import React from "react";
import { useEffect, useState } from "react";
import makkuseLogo from "../images/makkuse-logo.png";
import { Link, useLocation } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
// import AppContext from "../Context/AppContext";
function Header(props) {
  // const { setHideDropDown } = useContext(AppContext);
  // const hideDropDown = props.hideDropDown;
  const [navShow, setNavShow] = useState(false);
  const [productActive, setProductActive] = useState(false);
  const [customizedActive, setCustomizedActive] = useState(false);
  const [defaultKey, setDefaultKey] = useState(5);
  const Location = useLocation();

  // useEffect for toggling show class while clicking handburger menu
  useEffect(() => {
    if (navShow) {
      document.body.classList.add("show");
    } else {
      document.body.classList.remove("show");
    }
  }, [navShow]);
  useEffect(() => {
    window.addEventListener("click", () => {
      setDefaultKey(null);
    });
  }, []);
  const tuggling = () => {
    if (navShow === false) {
      setNavShow(true);
    } else {
      setNavShow(false);
    }
  };

  //function for clicking accordin header and item
  const productItemClick = () => {
    setDefaultKey(null);
    setProductActive(true);
    if (customizedActive) {
      setCustomizedActive(false);
    }
  };
  const handleProductClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (defaultKey === 0) {
      setDefaultKey(null);
    } else {
      setDefaultKey(0);
    }
  };
  const customizedItemClick = () => {
    setDefaultKey(null);
    setCustomizedActive(true);
    if (productActive) {
      setProductActive(false);
    }
  };
  const handleCustomizedClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (defaultKey === 1) {
      setDefaultKey(null);
    } else {
      setDefaultKey(1);
    }
  };
  useEffect(() => {
    if (
      Location.pathname === "/" ||
      Location.pathname === "/our-story" ||
      Location.pathname === "/gallery" ||
      Location.pathname === "/contact" ||
      Location.pathname === "/testimonial" ||
      Location.pathname === "/blog"
    ) {
      setProductActive(false);
      setCustomizedActive(false);
    } else if (Location.pathname.includes("customizedGifting")) {
      setCustomizedActive(true);
      setProductActive(false);
    } else {
      setProductActive(true);
      setCustomizedActive(false);
    }
    // if (defaultKey == 0 || defaultKey == 1) {
    //   document.body.addEventListener("click", (event) => {
    //     setDefaultKey(null);
    //     event.stopPropagation();
    //   });
    // }
  }, [Location.pathname]);
  // useEffect(() => {
  //   if (hideDropDown == true) {
  //     setDefaultKey(null);
  //   }
  // });
  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="header-logo-image">
                <div className="logo-container">
                  <Link to={"/"} onClick={() => setNavShow(false)}>
                    <img src={makkuseLogo} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className={navShow === true ? "nav show" : " nav"}>
                <div
                  className={navShow === true ? "nav-list show" : " nav-list"}
                >
                  <ul>
                    <li className="nav-item">
                      <Link
                        to="/"
                        className={
                          Location.pathname === "/" ||
                          Location.pathname === "/testimonial"
                            ? "nav-link active"
                            : " nav-link"
                        }
                        onClick={() => {
                          setDefaultKey(null);
                          setNavShow(false);
                        }}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="our-story"
                        className={
                          Location.pathname === "/our-story" ||
                          Location.pathname === "/blog"
                            ? "nav-link active"
                            : " nav-link"
                        }
                        onClick={() => {
                          setDefaultKey(null);
                          setNavShow(false);
                        }}
                      >
                        Our Story{" "}
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Accordion activeKey={defaultKey}>
                        <Accordion.Item
                          eventKey={0}
                          onClick={(e) => handleProductClick(e)}
                        >
                          <Accordion.Header>
                            {" "}
                            <Link
                              to={"#"}
                              className={
                                productActive ? "nav-link active" : " nav-link"
                              }
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                            >
                              Products
                            </Link>
                          </Accordion.Header>

                          {/* <div
                            id="collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          > */}
                          <Accordion.Body activeKey="1">
                            <ul className="dropdown-content">
                              <li>
                                <Link
                                  to="/product-pustakari"
                                  onClick={() => {
                                    productItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                  Pustakari
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/product-cookies"
                                  onClick={() => {
                                    productItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                  Cookies
                                </Link>
                              </li>

                              <li>
                                <Link
                                  to="/product-gundpak"
                                  onClick={() => {
                                    productItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                  Gundpak
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/product-other-special"
                                  onClick={() => {
                                    productItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                  Combo Boxes
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/product"
                                  onClick={() => {
                                    productItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                  All Products
                                </Link>
                              </li>
                            </ul>
                          </Accordion.Body>
                          {/* </div> */}
                        </Accordion.Item>
                      </Accordion>
                    </li>
                    <li className="nav-item dropdown">
                      <Accordion activeKey={defaultKey}>
                        <Accordion.Item
                          eventKey={1}
                          onClick={handleCustomizedClick}
                        >
                          <Accordion.Header>
                            {" "}
                            <Link
                              to={"#"}
                              className={
                                customizedActive
                                  ? "nav-link active"
                                  : " nav-link"
                              }
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                            >
                              Customized Gifting
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <ul className="dropdown-content">
                              <li>
                                <Link
                                  to="/customizedGifting/product-corporate-boxes"
                                  onClick={() => {
                                    customizedItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                  Corporate Gifting
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/customizedGifting/product-festive-gifting"
                                  onClick={() => {
                                    customizedItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                  Festive Gifting
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/customizedGifting/dashain-festive-gifting"
                                  onClick={() => {
                                    customizedItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                  Dashain Gifting
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/customizedGifting/tihar-festive-gifting"
                                  onClick={() => {
                                    customizedItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                  Tihar Gifting
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to="/customizedGifting/christmas-festive-gifting"
                                  onClick={() => {
                                    customizedItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                  Christmas Gifting
                                </Link>
                              </li>
                                  <li>
                                <Link
                                  to="/makkuse-dessert-bar"
                                  onClick={() => {
                                    customizedItemClick();
                                    setNavShow(false);
                                  }}
                                >
                                 Makkusé Outlet
                                </Link>
                              </li>
                            </ul>
                          </Accordion.Body>
                          {/* </div> */}
                        </Accordion.Item>
                      </Accordion>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/gallery"
                        className={
                          Location.pathname === "/gallery"
                            ? "nav-link active"
                            : " nav-link"
                        }
                        onClick={() => {
                          setDefaultKey(null);
                          setNavShow(false);
                        }}
                      >
                        Gallery
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/contact"
                        className={
                          Location.pathname === "/contact"
                            ? "nav-link active"
                            : " nav-link"
                        }
                        onClick={() => {
                          setDefaultKey(null);
                          setNavShow(false);
                        }}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="menu-toggle" onClick={tuggling}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
