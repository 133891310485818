import React, { useEffect, useState } from "react";
import gridImage from "../images/repeat-grid.png";
import makkuseLogo from "../images/makkuse-logo.png";
import { Link } from "react-router-dom";
function Footer() {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    const shopNow = document.querySelector(".shop-now");
    if (showModal) {
      shopNow.classList.add("show");
    } else {
      shopNow.classList.remove("show");
    }
    window.addEventListener("click", () => setShowModal(false));
    document.querySelector(".close-icons").addEventListener("click", (e) => {
      setShowModal(false);
    });
  }, [showModal]);

  return (
    <footer>
      <div className="repeat-grid">
        <img src={gridImage} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <div className="footer-logo">
              <Link to="/">
                <img src={makkuseLogo} alt="Makkusé logo" />
              </Link>
            </div>
          </div>
          <div className="col-md-2 col-sm-6">
            <div className="footer-content">
              <div className="header-box">
                <h4>Quick Links</h4>
              </div>
              <ul>
                <li>
                  <Link
                    to="#"
                    className="footer-link"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowModal(true);
                    }}
                  >
                    Send a gift to Nepal
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-sm-6">
            <div className="footer-content products">
              <div className="header-box">
                <h4>Products</h4>
              </div>
              <ul>
                <li>
                  <Link
                    to="/product-cookies"
                    className="footer-link"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Cookies
                  </Link>
                </li>
                <li>
                  <Link
                    to="/product-pustakari"
                    className="footer-link"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Pustakari
                  </Link>
                </li>
                <li>
                  <Link
                    to="/product-gundpak"
                    className="footer-link"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Gundpak
                  </Link>
                </li>
                <li>
                  <Link
                    to="/product-luxury-box"
                    className="footer-link"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Luxury Box
                  </Link>
                </li>
                {/* <li>
                  <Link to="/product-customized-box" className="footer-link">
                    Customized Gift Package
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-5">
            <div className="footer-content contact">
              <div className="header-box">
                <h4>Contact</h4>
              </div>
              <div className="footer-contact">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="20"
                    height="20"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 1024 1024"
                  >
                    <path
                      fill="#874F47"
                      d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5l39.3-50.5l42.8 33.3h643.1l42.8-33.3l39.3 50.5l-27.7 21.5zM833.6 232L512 482L190.4 232l-42.8-33.3l-39.3 50.5l27.6 21.5l341.6 265.6a55.99 55.99 0 0 0 68.7 0L888 270.8l27.6-21.5l-39.3-50.5l-42.7 33.2z"
                    ></path>
                  </svg>
                </div>
                <div className="text-container">
                  <a className="another-a" href="mailto:info@makkuse.com">
                    <p>info@makkuse.com</p>
                  </a>
                </div>
              </div>
              <div className="footer-contact">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="20"
                    height="20"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="#874F47"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.554 6.24L7.171 2.335c-.39-.45-1.105-.448-1.558.006L2.831 5.128c-.828.829-1.065 2.06-.586 3.047a29.207 29.207 0 0 0 13.561 13.58c.986.479 2.216.242 3.044-.587l2.808-2.813c.455-.455.456-1.174.002-1.564l-3.92-3.365c-.41-.352-1.047-.306-1.458.106l-1.364 1.366a.462.462 0 0 1-.553.088a14.557 14.557 0 0 1-5.36-5.367a.463.463 0 0 1 .088-.554l1.36-1.361c.412-.414.457-1.054.101-1.465Z"
                    ></path>
                  </svg>
                </div>
                <div className="text-container">
                  <a className="another-a" href="tel:+9779820106801">
                    <p>+977 9820106801</p>
                  </a>
                </div>
              </div>
              <div className="footer-contact">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="20"
                    height="20"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="#874F47"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.554 6.24L7.171 2.335c-.39-.45-1.105-.448-1.558.006L2.831 5.128c-.828.829-1.065 2.06-.586 3.047a29.207 29.207 0 0 0 13.561 13.58c.986.479 2.216.242 3.044-.587l2.808-2.813c.455-.455.456-1.174.002-1.564l-3.92-3.365c-.41-.352-1.047-.306-1.458.106l-1.364 1.366a.462.462 0 0 1-.553.088a14.557 14.557 0 0 1-5.36-5.367a.463.463 0 0 1 .088-.554l1.36-1.361c.412-.414.457-1.054.101-1.465Z"
                    ></path>
                  </svg>
                </div>
                <div className="text-container">
                  <a className="another-a" href="tel:+9779820106916">
                    <p>+977 9820106916</p>
                  </a>
                </div>
              </div>
              <div className="social-media-icon">
                <ul>
                  <li>
                    <a
                      href="https://m.me/makkuse.np"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          height="22"
                          width="22"
                          fill="#874F47"
                        >
                          <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z" />
                        </svg>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/makkuse.np/"
                      target="blank"
                    >
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="img"
                          width="22"
                          height="22"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 1024 1024"
                        >
                          <path
                            fill="#874f47"
                            d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3S645.3 585.4 645.3 512S585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165c-3.1-64-17.7-120.8-64.5-167.6c-46.9-46.9-103.6-61.4-167.6-64.5c-55.2-3.1-109.9-2.6-165-2.6c-55.2 0-109.9-.5-165 2.6c-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6c46.9 46.9 103.6 61.4 167.6 64.5c55.2 3.1 109.9 2.6 165 2.6c55.2 0 109.9.5 165-2.6c64-3.1 120.8-17.7 167.6-64.5c46.9-46.9 61.4-103.6 64.5-167.6c3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9S717.1 398.5 717.1 512S625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9s47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"
                          />
                        </svg>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/makkuse_np?s=20&t=iyJXQ9V_GU04IfNUC5HkKw"
                      target="blank"
                    >
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="img"
                          width="27"
                          height="22"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                          fill="#874F47"
                        >
                          <path d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z" />
                        </svg>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/makkuse"
                      target="blank"
                    >
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="img"
                          width="22"
                          height="22"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                          fill="#874F47"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 2.838A1.838 1.838 0 0 1 2.838 1H21.16A1.837 1.837 0 0 1 23 2.838V21.16A1.838 1.838 0 0 1 21.161 23H2.838A1.838 1.838 0 0 1 1 21.161V2.838Zm8.708 6.55h2.979v1.496c.43-.86 1.53-1.634 3.183-1.634c3.169 0 3.92 1.713 3.92 4.856v5.822h-3.207v-5.106c0-1.79-.43-2.8-1.522-2.8c-1.515 0-2.145 1.089-2.145 2.8v5.106H9.708V9.388Zm-5.5 10.403h3.208V9.25H4.208v10.54ZM7.875 5.812a2.063 2.063 0 1 1-4.125 0a2.063 2.063 0 0 1 4.125 0Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-1 col-sm-1">
            <div className="footer-content up-arrow">
              <li>
                <div onClick={() => window.scrollTo(0, 0)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#874F47"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-up-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <polyline points="16 12 12 8 8 12"></polyline>
                    <line x1="12" y1="16" x2="12" y2="8"></line>
                  </svg>
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-banner">
        <p>Powered by Unitech</p>
      </div>
    </footer>
  );
}

export default Footer;
