import React from "react";
// import mainImage from "../../images/banner-slider3.png";
// import tiharImage from "../../images/tihar-combo.png";
// import valentineImage from "../../images/valentines-combo.jpg";
// import luxuryBoxImage from "../../images/makkuse-lux.jpeg";
export const LuxuryBox = () => {
  return (
    <section className="section-luxury">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="image-container-big">
              <img
                // src={luxuryBoxImage}
                src={`${process.env.REACT_APP_BASE_URL}/images/makkuse-lux.jpeg`}
                alt="Special Collection Luxury Box"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="text-container underline">
              <h2>Special Collection Luxury Box for your next big event</h2>
            </div>
            <div className="para">
              <p>
                Our ultimate luxury box can be customized specially for major
                occasions like weddings, corporate events, rituals and
                festivities. We specialize in designing and rendering attractive
                customized boxes.
              </p>
            </div>
            <div className="image-row">
              <div className="image-container-small">
                <img
                  // src={tiharImage}
                  src={`${process.env.REACT_APP_BASE_URL}/images/tihar-combo.png`}
                  alt="Tihar Makkusé Combo Luxury Box"
                  loading="lazy"
                />
              </div>
              <div className="image-container-small">
                <img
                  // src={valentineImage}
                  src={`${process.env.REACT_APP_BASE_URL}/images/valentines-combo.jpg`}
                  alt="Valentine's Day Makkusé Combo Luxury Box"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
