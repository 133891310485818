import React, { useEffect, useState } from "react";
import playButton from "../../images/play-circle-regular.svg";
// import videoOverlay from "../../images/video-overlay.jpg";
const Video = () => {
  const [showVideo, setShowVideo] = useState(false);
  const addBodyClass = () => {
    setShowVideo(true);
    document.querySelector("body").classList.add("modal-opened");
  };
  useEffect(() => {
    const body = document.querySelector("body");
    const removeBodyClass = (e) => {
      if (showVideo) {
        setShowVideo(false);
        body.classList.remove("modal-opened");
      }
    };
    body.addEventListener("click", removeBodyClass);
    return () => body.removeEventListener("click", removeBodyClass);
  });
  return (
    <section className="section-video show-modal">
      <div className="text-container underline">
        <h2>Experience the Making of Authentic Nepali Desserts</h2>
      </div>
      <div className="video-container">
        <iframe
          src="https://www.youtube.com/embed/Iy8oXRGqqBo"
          title="Look at how it's made at the Makkusé factory!!!"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        {/* <div
          className="overlay"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_BASE_URL}/images/video-overlay.jpg)`,
          }}
        ></div>
        <div className="video-container-detail">
          <div className="play-pause">
            <div
              className="play-container"
              onClick={(e) => {
                e.stopPropagation();
                addBodyClass();
              }}
            >
              <img src={playButton} alt="Play Video Button" />
            </div>
          </div>
        </div> */}
      </div>
      {/*  {showVideo && (
        <div className="video-modal">
          <div className="image-container">
            <iframe
              width="853"
              height="480"
              src="https://www.youtube.com/embed/Iy8oXRGqqBo"
              title="Look at how it's made at the Makkusé factory!!!"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="header-box">
            <h5>
              Sharing the authentic luxurious dessert recipes from the Himalayas
            </h5>
          </div>
        </div>
      )} */}
    </section>
  );
};

export default Video;
