import React, { useEffect } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import ShopModal from "../../Components/ShopModal";

const NepaliSagun = () => {
  useEffect(() => {
    document.title = "The Nepali Sagun | Makkusé";
  }, []);
  const initialData = {
    secondayBanner: {
      bannerTitle: "The Nepali Sagun",
      bannerDetail: "",
      bannerImg: `${process.env.REACT_APP_BASE_URL}/images/banner-image-for-blog.jpg`,
      page: "sagun",
    },
  };
  return (
    <main className="blogs">
      <SecondaryBanner bannerInfo={initialData.secondayBanner} />
      <section className="section-blog" style={{ marginBlock: "100px" }}>
        <div className="container">
          {/* <div className="sub-topic">
            <h2 style={{ fontWeight: "400" }}>
              There are so many exciting conversations waiting to happen within
              the Nepali dessert space – here are some thoughts from our team,
              clients and enthusiasts! Do have a read and leave your thoughts!
            </h2>
          </div> */}
          <div className="text-container">
            <div className="para">
              <p>
                Sagun: /səˈgun/ सगुन , is a polite and original cultural
                practice widely followed in the Hindu culture and also
                significantly practiced in the Newari culture too. Sagun is a
                word taken from Sanskrit, which simply means auspicious. The
                origin and culture of Sagun bases itself on the Vedic Scriptures
                and Hindu textbooks.
              </p>
              <p>
                Sagun is considered to be a way of bringing good energy and
                harmony to the lives of the connected people. When a Sagun is
                being exchanged, it symbolizes the exchange of prayers between
                the giver and receiver. As part of the belief system, Sagun also
                depicts the debt to be paid by the receiver in the next meeting
                with the giver, which insists that the receiver and giver shall
                meet again.
              </p>
              <p>
                The offering of Sagun as part of a wedding is a way of
                announcing the wedding in an unofficial manner. That way the
                families of the bride to be and groom to be are involved in a
                strong bond of commitment. Sagun is also considered beneficial
                in attaining the blessings of the divine. By offering Sagun, we
                are inviting good omens, happiness, and auspicious powers into
                our lives.
              </p>
              <p>
                Sagun generally consists of eatables like dry fruits and sweets,
                wearables, jewelry and clothing and money which are offered in a
                collective manner. Sagun signifies the auspicious wishes for the
                abundance of riches, good clothes to wear and good food to eat.
                This way, a Sagun makes a good addition to the good omen from
                the giver to the receiver. In addition to marriage ceremonies,
                Hindus frequently offer Sagun throughout various rituals,
                events, and festivals. Sagun is frequently provided by attendees
                and invitees at ceremonies and weddings. Sagun serves as a
                symbol of them honoring those organizing the wedding and
                spreading their joy. It is also a means of receiving divine
                favors.
              </p>
              <p>
                Like the Hindu culture, in the Newari culture (which has both
                Hindu as well as Buddhist practices followed), Sagun is one
                important part of the rituals and ceremonies. In the Newari
                Culture, all kinds of rituals such as weddings, anniversaries,
                birthdays, rice-feeding ceremonies and any ritual which includes
                worshiping of the divine entities. In Newari culture, Sagun is
                regarded as very important and inseparable during the rituals
                and is second to none when it comes to exchanging goodwill.
              </p>
              <p>
                Sagun is generalized into two categories in Newari culture:
                “Dhau Saga:” and “Khe Saga:”. The Dhau Saga: means the items
                which are related with the yogurt or curd such as: Akshata,
                Tika, sweets etc. whereas the Khe Saga: means the items such as:
                egg, fish, meat, “Baara” etc. These components of the Kho Sag
                signify the five elements: Air, Sky, Fire, Water and Earth. This
                culture brings in the significance of the religious beliefs as
                well as good omen between the receiver and giver.
              </p>
              <p>
                Traditionally, during the rituals, the attendees take something
                sweet with them as Sagun. Something sweet as an addition in the
                Sagun adds to the good wishes and gratefulness of the attendees.
                It is quite a normal and widely practiced ritual to offer sweets
                and exchange goodwill in Newari culture. The eatables and sweets
                are of various types and kinds such that the palette has a
                variety of flavors. Milk, Khuwa and sweets are generally
                considered as good components of a Sagun. Weddings and other
                auspicious events are made more auspicious with these items in
                the Sagun.
              </p>
              <p>
                Proudly manufactured in Nepal, adding the warmth of finest dairy
                produce, Makkusé brings only the best Khuwa and Milk from the
                outskirts of Banepa to make the most delectable Gundpak,
                Pustakari and Cookies. All these items made with purity and
                love, these items are sure to bring a brilliant addition to your
                Sagun and also to your palette. Makkusé has always been popular
                as the best gift item amongst its consumers.
              </p>
              <p>
                Packaging which incorporates Nepali elements, taste which feels
                like warmth of the Sun in the Himalayas and rendering of the
                products which feels like a warm hug. This wedding season, let
                us all promote exchange of good vibes, wishes and auspiciousness
                in the form of a scrumptious Nepali brand. Exchange authentic,
                Nepali love in the form of scrumptious Makkusé Gundpak, Cookies
                and Pustakari which are available in various gift packs. Custom
                packs of these combinations are sure to make everyone astounded.
              </p>
              <p>
                There’s nothing like original Nepali desserts and what better
                way to gift these to your loved ones this wedding season other
                than Makkusé? Write to us:
                <a className="another-a" href="mailto:info@makkuse.com">
                  info@makkuse.com
                </a>
              </p>
            </div>
            <div className="bottom-text">
              <p>Blog Written By:</p>
              <p>Ankit Darnal</p>
              <p>Graphics Designer</p>
              <p>Makkusé Nepal</p>
            </div>
          </div>
        </div>
      </section>
      <ShopModal />
    </main>
  );
};

export default NepaliSagun;
