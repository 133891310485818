import React, { useEffect } from "react";
//import component
import SecondaryBanner from "../../Components/SecondaryBanner";
import ShopModal from "../../Components/ShopModal";

//import images
/* import testimonialBanner from "../../images/makkuse-lux.jpeg";
import sudipImage from "../../images/client.png";
import reeghaImage from "../../images/client-reegha.png";
import hisilaImage from "../../images/client-hisila.png"; */

const TestimonialPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Testimonials | Makkusé";
  }, []);
  const initialData = {
    secondayBanner: {
      bannerTitle: "What our customers are saying",
      bannerDetail: "",
      bannerImg: `${process.env.REACT_APP_BASE_URL}/images/makkuse-lux.jpeg`,
    },
  };
  const testimonials = [
    {
      id: 1,
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/client.png`,
      title: "Taste of Nepali Nostalgia",
      quote:
        "My family sent me Makkusé products as a gift after I came to Australia, never had I imagined it would give me a taste of Nepali nostalgia, all so wholesome. Definitely recommended.",
      name: "Sudip Neupane",
      address: "Perth, Australia",
    },
    {
      id: 2,
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/client-reegha.png`,
      title: "Exceptional Customer Service",
      quote:
        "Love the concept and the execution. Exceptional customer service. Even as a young start up the effort you guys put to deliver well to each customer is commendable. Wish you the best! Nepalis now have something they can proudly take abroad as a gift!",
      name: "Reegha Rajbhandari",
      address: "Sydney, Australia",
    },
    {
      id: 3,
      imageSrc: `${process.env.REACT_APP_BASE_URL}/images/client-hisila.png`,
      title: "Absolutely Yummy",
      quote: `'Makkusé', actually justifies its name…’absolutely yummy’ What I like the most is, its originality, that gives original-traditional smell with ethnic taste. In love with its variation & all sooo delicious`,
      name: "Hisila Nakarmi",
      address: "Kathmandu, Nepal",
    },
  ];
  return (
    <main className="page-testimonials">
      <SecondaryBanner bannerInfo={initialData.secondayBanner} />

      <section className="section-testimonial">
        <div className="container">
          <div className="sub-topic">
            <p className="topic-regular">
              Experience the traditional past, a playful present and an
              innovative past with our range of pustakari, gundpak and
              Experience the traditional past, a playful present and an
              innovative past with our range of pustakari, gundpak and
            </p>
          </div>
          <div className="row">
            {testimonials.map((testimonial) => {
              return (
                <div className="col-md-6" key={testimonial.id}>
                  <div className="testimonial-container">
                    <div className="testimonial-content">
                      <div className="client-header">
                        <div className="client-image">
                          <img src={testimonial.imageSrc} alt="" />
                        </div>
                        <div className="client-quote-title">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            role="img"
                            width="92.68"
                            height="73.38"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="white"
                              d="M3.691 6.292C5.094 4.771 7.217 4 10 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 6.925 10H10a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2H3a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789zM20 20h-6a1 1 0 0 1-1-1v-5l.003-2.919c-.009-.111-.199-2.741 1.688-4.789C16.094 4.771 18.217 4 21 4h1v2.819l-.804.161c-1.37.274-2.323.813-2.833 1.604A2.902 2.902 0 0 0 17.925 10H21a1 1 0 0 1 1 1v7c0 1.103-.897 2-2 2z"
                            />
                          </svg>
                          <h3>{testimonial.title}</h3>
                        </div>
                      </div>
                      <div className="client-quote-container">
                        <div className="client-quote">
                          <p>{testimonial.quote}</p>
                        </div>
                        <div className="review-head">
                          <p>
                            <strong>{testimonial.name}</strong>
                          </p>
                          <p>
                            <strong>{testimonial.address}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <ShopModal />
    </main>
  );
};

export default TestimonialPage;
