import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SecondaryBanner from "../../Components/SecondaryBanner";
import ShopModal from "../../Components/ShopModal";
import Packaging from "./Packaging";
import { Link, useLocation } from "react-router-dom";
import SEO from "../../Components/Seo";
/* import ourstoryBanner from "../../images/makkuse-lux.jpeg";
import ourstoryBanner1 from "../../images/festive-banner.jpg";
import productImage from "../../images/product-image.png"; */
export default function CustomizedGifting() {
  const { id } = useParams();
  const [propData, setPropData] = useState({});
  const [showform, setShowform] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();
  useEffect(() => {
    switch (id) {
      case "product-festive-gifting":
        setPropData({
          bannerTitle: "Festive Gifting",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/festive-banner.jpg`,
          bannerDetail: "",
        });
        break;
      case "dashain-festive-gifting":
        setPropData({
          bannerTitle: "Dashain Gifting",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/festive-banner.jpg`,
          bannerDetail: "",
        });
        break;
      case "tihar-festive-gifting":
        setPropData({
          bannerTitle: "Tihar Gifting",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/festive-banner.jpg`,
          bannerDetail: "",
        });
        break;
      case "christmas-festive-gifting":
        setPropData({
          bannerTitle: "Christmas Gifting",
          bannerImg: `/images/chr3.jpeg`,
          bannerDetail: "",
        });
        break;
   
      default:
        setPropData({
          bannerTitle: "Corporate Gifting",
          bannerDetail: "",
          bannerImg: `${process.env.REACT_APP_BASE_URL}/images/makkuse-lux.jpeg`,
        });
    }
  }, [id]);
  useEffect(() => {
    if (showform) {
      document.body.classList.add("modal-opened");
    } else {
      document.body.classList.remove("modal-opened");
    }
  }, [showform]);

  useEffect(() => {
    if (submitCount) {
      async function sendMail() {
        try {
          const response = await axios.post(
            "https://api.uniboard.unitechmedia.com.np/api/makkuseOrders",
            {
              name: name,
              phone: phone,
              email: email,
              message: message,
            }
          );
          if (response.data) {
            setSubmitCount(0);
            setIsPending(false);
            setEmail("");
            setName("");
            setPhone("");
            setMessage("");
          }
          // console.log(response);
        } catch (error) {
          console.log(error);
        }
      }
      sendMail();
    }
  }, [submitCount]);
  function handleFormSubmit(e) {
    e.preventDefault();
    setIsPending(true);
    setSubmitCount((prevCount) => prevCount + 1);
  }
  console.log(location)
  return (
    <main className="page-product-details">
      {location.pathname === "/customizedGifting/product-corporate-boxes" && <SEO title="Personalized corporate gifts for employees | Makkuse Corporate Gifting" description="Discover Custom Corporate Gifts to Acknowledge and Appreciate Your Valued Team Members. Create Lasting Impressions with our corporate gifting solution." keywords="corporate gift set, memorable corporate gifts, personalised corporate gifts, corporate Christmas gifts, corporate gift cards, branded corporate gifts, corporate gifts for employees, corporate Diwali gifts for employees, corporate gift service, corporate gift supplier, why corporate gifting is important, corporate Diwali gifts, corporate gifting solutions, corporate gifts Nepal, benefits of corporate gifts, business corporate gifts, corporate gift baskets, corporate gift hampers, corporate gift ideas, corporate gifts for clients, corporate gifts in Nepal,Personalized corporate gifts, memorable corporate gifts, corporate gift set, corporate gifts, corporate gifting ideas for clients, Corporate Gift Nepal, unique client gift ideas." canonical="https://makkuse.com/customizedGifting/product-corporate-boxes" />}
      {location.pathname === "/customizedGifting/product-festive-gifting" && <SEO title="Festive Gifting Nepal | Makkuse" description="The Ultimate Father's Day Gift Box includes Pustakari in a handcrafted box, Gundpak filled Cookies, two jars of decadent Gundpaks, a handwoven Dhaka Topi, Pocket Square and Tie, a Buwa card for your special message, and a handmade pure Copper Spoon." keywords="father's day gift ideas, father's day gift box, gift for father on father's day, what to gift father on father's day, top father day's gift,Teej Gift hamper, Teej gift, Teej Special Gift, Teej Gift ideas, Gifts of teej function,product-festive-gifting" canonical="https://makkuse.com/customizedGifting/product-festive-gifting" />}
      {location.pathname === "/customizedGifting/dashain-festive-gifting" && <SEO title="Kathmandu Koseli for Dashain | Makkuse" description="Makkusé is about sweetening those moments with a dash of authentic Nepali desserts. So, for the grandest, most epic family reunion, why settle for the same old, boring Dashain gifts when you can be the rockstar of the festival with Makkusé? Trust us: with the Swadeshi spirit reflected in every dessert we create, your gift will be the talk of the town or at least your family gatherings!" keywords="Dashain gift, Dashain corporate gifts, Dashain gift pack, Dashain gifts in Nepal, Dashain gift items, Happy Dashain gift, Dashain gift pack, gift for Dashain, gifts for Dashain, Top gifts to give in Dashain, Gift for Dashain Festival, Dashain special gift, Dashain Gift to Nepal" canonical="https://makkuse.com/customizedGifting/dashain-festive-gifting" />}
      {location.pathname === "/customizedGifting/tihar-festive-gifting" && <SEO title="Tihar Gifting Made Easy with Makkusé" description="Choose Makkusé for a wide ray of products such as MhaPuja Box and Bhai Tika Box to gift your siblings, friends, family, and corporate." keywords="Tihar gifts, Bhai Tika, tihar gifts for brothers, Bhai Tika gift" canonical="https://makkuse.com/customizedGifting/tihar-festive-gifting" />}
      {location.pathname === "/customizedGifting/christmas-festive-gifting" && <SEO title="Make your Christmas Merry" description="Skip the holiday rush and shop conveniently from the comfort of your home. Visit our website and buy Christmas gifts online and through our socials" keywords="Christmas gift ideas, Christmas gifts for mom, Christmas gift ideas for staff, corporate Christmas gifts, Christmas gift hampers, Christmas gifts for girlfriend, Christmas gifts for wife, budget-friendly Christmas gifts, Christmas gift boxes, best Christmas gifts, Buy Christmas gifts online, Family Christmas gift ideas, Merry Christmas gift " canonical="https://makkuse.com/customizedGifting/christmas-festive-gifting" />}
      {location.pathname === "/customizedGifting/winter-box" && <SEO title="Winter Delights | Makusse" description="As winter unfolds, its frosty wonders, and the air becomes crisp, let the taste of Gundpak, Pustakari, and Cookies warm your heart and remind you of this enchanting season's magic. Whether enjoyed as a sweet snack on a frosty day or shared with loved ones, these treats are essential to the winter experience.Gundpak, the perfect sweet indulgence to accompany a cup of hot coffee on a cold winter’s day, is made with pure fresh milk with an addition of aromatic coffee; it's often combined with crushed dry fruits to create a hearty, energy-packed snack. The rich, nutty sweetness and melt-in-your-mouth texture are also available in sugar-free options." keywords="Winter, Winter special gift" canonical="https://makkuse.com/customizedGifting/winter-box" />}
      <SecondaryBanner bannerInfo={propData} />
      <section className="section-product-details">
        <div className="container">
          <div className="row">
            <div className={`${id === 'product-corporate-boxes' ? 'col-lg-12' : 'col-lg-12'}`}>
              <div className="text-container text-container-custom">
                {id === "product-corporate-boxes" && (
                  <div className="para">
                    <div className="image-container1">
                      <img
                        src={`/images/chr2.jpeg`}
                        alt="Personalized Corporate Gifts by Makkusé"
                      />
                    </div>
                    <div className="text-center underline mb-4 mt-4">
                      <h2>Personalized Corporate Gifts by Makkusé</h2>
                    </div>
                    <p>
                      We understand the essence of corporate gifting and offer a unique approach that goes beyond the ordinary. Make your corporate gifts a symbol of authenticity and heartfelt appreciation. Our Swadeshi spirit runs deep, and we take immense pride in curating corporate gifting options that are made with local <a href="https://makkuse.com/">Nepali product</a>.
                    </p>
                    <div className="text-center underline mb-4 mt-4">
                      <h2>Corporate Gifts for Employees & Clients</h2>
                    </div>
                    <p>
                      Create lasting impressions and nurture relationships by discovering the magic of memorable corporate gifts with Makkusé. Explore our range of corporate gift sets that reflect the unique culture of Nepal. With personalized gift options, we have ideas that will touch the hearts of your clients. Our offerings include the cherished Pustakari, delicious Gundpak, and our delectable Cookies, embody the essence of Nepali culinary heritage.
                    </p>
                    <div className="text-center underline mb-4 mt-4">
                      <h2>Memorable Corporate Gifts</h2>
                    </div>
                    <p>
                      We have had the opportunity to curate Makkusé gift boxes for leading Nepali corporates such as Nabil Bank, IME group, MAW enterprises and have been immensely proud and humbled to serve embassies and government organizations as well. Whenever it comes to corporate gifts, something unique and memorable and supporting local businesses look no further than Makkusé, the epitome of great quality, great taste, and great presentation that is sure to create lasting impressions and nurture relationships to all your stakeholders by discovering the magic of memorable corporate gifts with Makkusé.
                    </p>
                  </div>
                )}
                {id === "product-festive-gifting" && (
                  <div className="para">
                    {/* <p>
                      We’ve designed and produced festive gift boxes for
                      customers to give to their loved ones during various
                      festivals like Teej, Bhai Tika, Mothers’ Day, Fathers’
                      Day, Tihar etc. We incorporate the elegant colors of
                      Nepali festivities into our luxurious brand and we keep
                      the quality and essence of the festivals intact while we
                      customize the gift boxes. Authenticity, luxury and
                      auspiciousness of the Nepali culture are always our sole
                      priority during production.
                    </p> */}
                    <div>
                      <div className="image-container1">
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/images/product-image.png`}
                          alt="Personalized Corporate Gifts by Makkusé"
                        />
                      </div>
                      <div className="text-center underline mb-4 mt-4">
                        <h2>Father's Day</h2>
                      </div>
                      <p>"The Sweetest Thank You: An ideal Father's Day Gift"</p> <br />
                      <p>Father's Day is just around the corner, and you are on the hunt for the top Father's Day gift ideas. Finding the perfect gift for your dad on Father's Day can be a delightful challenge, but with Makkusé , it becomes a sweet breeze. We have curated the perfect Father's Day gift box that captures the heart of the occasion. Our gift box is more than just a gift; it's a heartfelt tribute to the Hero in your life. Each bite tells a story of our rich Nepali culture, the legacy of our ancestors, and the values passed down from generation to generation. In addition to the best tasting desserts our special Father’s Day gift boxes include equally special accessories made with handwoven Nepali Dhaka. This special fabric takes two skilled weavers seven hours to weave. Choose Makkusé for the top Father's Day gift that speaks volumes without words.</p>
                      <br />
                      <p>The Ultimate Father's Day Gift Box includes Pustakari in a handcrafted box, Gundpak filled Cookies, two jars of decadent Gundpaks, a handwoven Dhaka Topi, Pocket Square and Tie, a Buwa card for your special message, and a handmade pure Copper Spoon.</p> <br />
                      <p>The Father’s Day Treat Box includes Pustakari in a beautiful net pouch, a jar of delicious Gundpak, a Hand Woven Dhaka tie, and a Buwa card for your special message and a handmade Copper Spoon.
                        Both these offerings are available with Diabetic-Friendly alternatives as well upon request.
                      </p>
                    </div>
                    <div style={{ marginTop: "3rem" }}>
                      <div className="text-center underline mb-4 mt-3">
                        <h2 className="">Teej Bliss with Makkusé</h2>
                      </div>
                      <p>The auspicious festival of Teej is just around the corner. This vibrant festival holds significant cultural and traditional importance, especially among women. What began perhaps as a tradition to pray for the longevity of one’s husband’s life and health, has today evolved into a festival of women celebrating their womanhood - fierce, fun and frolic in Red!</p> <br />
                      <p>Starting the Teej function the grand feast known as "Daar," where women indulge in various delicacies before beginning their fast the next day. Fasting is known to be an expression of well-being. Additionally, artistic expression through dancing, singing, decked in red saris, and colorful bangles is an amazing sight of Nepali women celebrating their sense of empowerment and confidence.</p> <br />
                      <p>Also a tradition of gifting “Teej Bhag” - a pack of sweets, and wearables such as lacha is a popular one. Our Teej Special Gifts are thoughtfully curated to honor tradition and festivity. This Teej season, we bring to you the perfect bhag for your sisters, friends, fupus, maijus, bhaujus, devranis, jethanis, nandas, bhanjis, bhatijees and more! As you loved ones delve into devotional pujas and rituals, show that your love is as precious as always with a gift of Makkusé . From beautifully designed gift hampers to delectable treats that capture the essence of the festival, our offerings are a heartfelt way to express your love and appreciation.</p> <br />
                      <p>Our Teej pack includes Pustakari in a beautiful net pouch, a jar of delicious Gundpak, a Lacha, and beautiful pieces of red bangles.</p>
                    </div>
                  </div>
                )}
                {id === "dashain-festive-gifting" && (
                  <div className="para">
                    <div>
                      <div className="image-container1">
                        <img
                          src={`/new-img/dashain.jpg`}
                          alt="Personalized Corporate Gifts by Makkusé"
                        />
                      </div>
                      <div className="text-center underline mb-4 mt-4">
                        <h2>“Kathmandu Koseli for Dashain”</h2>
                      </div>
                      <p>Dashain, the grandest festival in Nepal, celebrates the victory of “Good over Evil.” it is a time when families come together, blessings are exchanged, and traditions are honoured. Perhaps driven by the belief that Dashain is an auspicious time to make any kind of purchase, exchanging sweets during Dashain holds a special place in Nepalese culture.
                      </p>
                      <br />
                      <p>In those times, when you finally take a break from your study work and get to go back home for the family union, do not go empty-handed. With Make in Nepal, Made in Nepal spirit, take a Kathmandu Koseli to express your love and appreciation that is just perfect to hit the nostalgia. </p>
                      <br />
                      <p>Makkusé is about sweetening those moments with a dash of authentic Nepali desserts. So, for the grandest, most epic family reunion, why settle for the same old, boring Dashain gifts when you can be the rockstar of the festival with Makkusé? Trust us: with the Swadeshi spirit reflected in every dessert we create, your gift will be the talk of the town or at least your family gatherings!
                      </p>
                      <br />
                      <p>Our Dashain Luxury Gift Box includes two jars of decadent Gundpak, a big box of Pustakari, six large Gundpak-filled cookies and a handmade copper spoon.
                        Our Dashain Small Gift Box includes four small Gundpak-filled cookies, a small box of Pustakari, a jar of decadent Gundpak and a handmade Copper Spoon. Both are available in a range of colour options.
                      </p>
                      <br />
                      <p>Just visit our website or DM us on our socials on your way to the Dashain Gift Box that speaks to your soul, and voila! It'll be on its way to your doorstep.
                      </p>
                    </div>
                  </div>
                )}
                {id === "tihar-festive-gifting" && (
                  <div className="para">
                    <div>
                      <div className="image-container1">
                        <img
                          src={`/new-img/tihar.webp`}
                          alt="Personalized Corporate Gifts by Makkusé"
                        />
                      </div>
                      <div className="text-center underline mb-4 mt-4">
                        <h2>“Tihar Gifting Made Easy with Makkusé”</h2>
                      </div>
                      <p>In the heart of Nepal, as the golden hues of autumn arrive, there's a gentle whisper in the air, and it says, "Tihar is coming!" It may be the second biggest festival in Nepal, but it's the grandest in the people's hearts as it symbolizes the victory of light over darkness and good over evil.
                      </p>
                      <br />
                      <p>The five beautiful days are a heartwarming reminder that during Tihar, we embrace the spirit of unity and the precious bond that ties all living beings together. Starting the Tihar with “Kaag Tihar”, crows are worshipped and thanked by offering grains on the banana leaf to avoid misfortune.</p>
                      <br />
                      <p>Followed by “Kukur Tihar”, a marigold mala with tika and food are offered to the dogs with gratitude towards their loyalty and friendship. On the third day, our hearts fill with anticipation as we prepare to welcome the goddess of wealth and luck, goddess Laxmi.
                      </p>
                      <br />
                      <p>The entire country transforms into a world of glittering colour as people light up the day candles and make beautiful rangoli patterns, waiting for the goddess to come. It is believed that making purchases during Tihar can attract prosperity. The fourth day, Mahpuja, also known as the New Year of the Newari, is about celebrating oneself.
                        A special ceremony where they worship themselves is a reminder that before worshipping external deities, one should first recognize the importance of self-improvement and inner well-being.
                      </p>
                      <br />
                      <p>The fifth and last day of Tihar is called Bhai Tika. The story of Yama, the God of Death, and his sister Yamuna's yearning to meet her brother is a tale of love and longing that resonates with every sibling. Her gestures are simple yet profound when they finally reunite – the five-coloured tika, vibrant flowers, and a heartwarming prayer. She circles him with mustard oil, flowers, and Dubo grass, symbolising her love and a wish for his well-being.
                      </p>
                      <p>Today, this beautiful tradition lives on. Sisters adorn their brothers with the Makhamali flower, mustard oil, and common walnuts, praying for their longevity, health, and prosperity. It's a ritual that goes beyond words, expressing love, gratitude, and a desire for a joy-filled future.</p>
                      <br />
                      <p>But Tihar is more than rituals; it's about exchanging gifts – tokens of affection that capture the essence of the sibling bond. The gifting culture has become transactional. In the spirit of Tihar, put a smile on your brother’s face through our nostalgic desserts.
                      </p>
                      <br />
                      <p>Choose Makkusé for a wide ray of products such as MhaPuja Box and Bhai Tika Box to gift your siblings, friends, family, and corporate.
                      </p>
                      <br />
                      <p>   Just visit our website or DM us on our socials on your way to the Tihar Gift Box that speaks to your soul, and voila! It'll be on its way to your doorstep.
                      </p>
                    </div>
                  </div>
                )}
                {id === "christmas-festive-gifting" && (
                  <div className="para">
                    <div>
                      <div className="image-container1">
                        <img

                          src={`/new-img/christmas-festive/christmas-5.jpg`}
                          alt="Personalized Corporate Gifts by Makkusé"
                        />
                      </div>
                      <div className="text-center underline mb-4 mt-4">
                        <h2>“Make your Christmas Merry”</h2>
                      </div>
                      <p>Christmas – the most wonderful time of the year, filled with joy, love, and the excitement of giving and receiving gifts. As we embrace the festive season, it's time to spread the holiday cheer with the perfect Christmas gifts! How can Makkusé ignore the festive vibes of December, then? For a fun and festive way to exchange gifts with your friends, colleagues, and family, consider organizing a Secret Santa gift exchange! From authentic Nepali desserts to exquisite gift boxes, we've got plenty of special options that will keep everyone guessing until the big reveal. Our selection of Secret Santa gifts includes:</p>
                      <ul className="mt-4">
                        <li><p>Handcrafted pustakari.</p></li>
                        <li><p>A jar of decadent gundpak.</p></li>
                        <li><p>A ceramic cup.</p></li>
                        <li><p>A cup mat.</p></li>
                        <li><p>Two Makkusé gundpak-filled cookie pouches that will add an element of surprise and excitement to your gift exchange.</p></li>
                      </ul>
                      <br />
                      <p>Skip the holiday rush and shop conveniently from the comfort of your home. Visit our website and buy Christmas gifts online and through our socials. </p>
                    </div>
                  </div>
                )}
                {id === "winter-box" && (
                  <div className="para">
                    <div>
                      <div className="image-container1">
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/images/product-image.png`}
                          alt="Personalized Corporate Gifts by Makkusé"
                        />
                      </div>
                      <div className="text-center underline mb-4 mt-4">
                        <h2>“Winter Delights”</h2>
                      </div>
                      <p>As winter unfolds, its frosty wonders, and the air becomes crisp, let the taste of Gundpak, Pustakari, and Cookies warm your heart and remind you of this enchanting season's magic. Whether enjoyed as a sweet snack on a frosty day or shared with loved ones, these treats are essential to the winter experience.
                        
                      </p>
                      <br/>
                      <p>Gundpak, the perfect sweet indulgence to accompany a cup of hot coffee on a cold winter’s day, is made with pure fresh milk with an addition of aromatic coffee; it's often combined with crushed dry fruits to create a hearty, energy-packed snack. The rich, nutty sweetness and melt-in-your-mouth texture are also available in sugar-free options.</p>
                      <br />
                      <p>Pustakari is another beloved winter delicacy that holds a special place in the hearts of those who savour it. Often enjoyed during winter festivals and gatherings, Pustakari will hit a taste of nostalgia and tradition. Its crunchy texture and earthy sweetness are a true reflection of the season's charm.</p>
                      <br />
                      <p>Whether it's classic choco fudge gundpak filled cookies, oatmeal cookies, or semolina gudpak filled cookies, are a quintessential winter delight. Packed inside our Signature Luxury box are your favourite Makkusé goodies, a beautiful ceramic mug handcrafted in Bhaktapur and a felted coaster made by women entrepreneurs in the outskirts of Kathmandu</p>
                      <br />
                      <p>Order your winter special gift box from Makkusé, and let the magic of winter unfold with each delightful surprise.</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="icon-section">
                <h5>Share:</h5>
                <div className="icon-container">
                  <a
                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdev.makkuse.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M18.3 0.5H2.2C1.61722 0.499999 1.05824 0.731235 0.645778 1.14295C0.233312 1.55467 0.00105961 2.11322 0 2.696L0 18.804C0 19.3875 0.231785 19.9471 0.644365 20.3596C1.05695 20.7722 1.61652 21.004 2.2 21.004H8.48V14.03H5.594V10.75H8.477V8.25C8.477 5.406 10.17 3.835 12.763 3.835C13.6143 3.84664 14.4635 3.9205 15.304 4.056V6.85H13.873C13.6291 6.81752 13.3811 6.84031 13.1472 6.9167C12.9134 6.99309 12.6997 7.12111 12.522 7.29129C12.3444 7.46146 12.2073 7.66943 12.1209 7.89978C12.0345 8.13013 12.001 8.37696 12.023 8.622V10.75H15.17L14.67 14.03H12.023V21H18.3C18.8835 21 19.4431 20.7682 19.8556 20.3556C20.2682 19.9431 20.5 19.3835 20.5 18.8V2.696C20.4989 2.11322 20.2667 1.55467 19.8542 1.14295C19.4418 0.731235 18.8828 0.499999 18.3 0.5Z" />
                    </svg>
                  </a>
                </div>

                <div className="icon-container">
                  <a
                    href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fdev.makkuse.com"
                    rel="noopener"
                  >
                    <svg
                      width="22"
                      height="23"
                      viewBox="0 0 21 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M18.3239 0.5H2.22393C1.64114 0.499999 1.08217 0.731235 0.669703 1.14295C0.257238 1.55467 0.0249854 2.11322 0.0239258 2.696L0.0239258 18.804C0.0239258 19.3875 0.255711 19.9471 0.668291 20.3596C1.08087 20.7722 1.64045 21.004 2.22393 21.004H18.3239C18.9074 21.004 19.467 20.7722 19.8796 20.3596C20.2921 19.9471 20.5239 19.3875 20.5239 18.804V2.696C20.5229 2.11322 20.2906 1.55467 19.8781 1.14295C19.4657 0.731235 18.9067 0.499999 18.3239 0.5ZM16.0899 7.767C16.0989 7.895 16.0989 8.028 16.0989 8.156C16.1068 9.27955 15.8913 10.3935 15.4649 11.433C15.0386 12.4725 14.4099 13.417 13.6154 14.2115C12.8209 15.006 11.8765 15.6346 10.8369 16.061C9.79738 16.4873 8.68347 16.7028 7.55993 16.695C5.92694 16.6985 4.32751 16.2316 2.95293 15.35C3.19302 15.3761 3.43442 15.3885 3.67593 15.387C5.02615 15.39 6.33816 14.9389 7.40093 14.106C6.77472 14.0946 6.16774 13.8877 5.66482 13.5145C5.16191 13.1412 4.7882 12.6201 4.59593 12.024C5.04608 12.1015 5.50754 12.0827 5.94993 11.969C5.27113 11.8304 4.66121 11.4612 4.22372 10.924C3.78624 10.3868 3.54817 9.71479 3.54993 9.022V8.985C3.96478 9.21716 4.42886 9.3474 4.90393 9.365C4.49243 9.09069 4.15513 8.71891 3.92204 8.28274C3.68895 7.84657 3.5673 7.35954 3.56793 6.865C3.56446 6.3328 3.70436 5.80948 3.97293 5.35C4.72636 6.27789 5.66658 7.03688 6.73249 7.57768C7.79841 8.11848 8.96618 8.42898 10.1599 8.489C10.0115 7.84518 10.0791 7.1704 10.3525 6.56887C10.6258 5.96734 11.0896 5.47254 11.6721 5.16088C12.2547 4.84921 12.9237 4.73804 13.5758 4.84452C14.2279 4.951 14.8267 5.26921 15.2799 5.75C15.9504 5.62112 16.5934 5.37647 17.1799 5.027C16.9561 5.71961 16.4875 6.30692 15.8619 6.679C16.4588 6.61123 17.042 6.45379 17.5919 6.212C17.1843 6.81279 16.6762 7.33883 16.0899 7.767Z" />
                    </svg>
                  </a>
                </div>
                <div className="icon-container">
                  <a
                    href="https://www.linkedin.com/company/makkuse"
                    target="blank"
                  >
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="img"
                        width="23"
                        height="23"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 2.838A1.838 1.838 0 0 1 2.838 1H21.16A1.837 1.837 0 0 1 23 2.838V21.16A1.838 1.838 0 0 1 21.161 23H2.838A1.838 1.838 0 0 1 1 21.161V2.838Zm8.708 6.55h2.979v1.496c.43-.86 1.53-1.634 3.183-1.634c3.169 0 3.92 1.713 3.92 4.856v5.822h-3.207v-5.106c0-1.79-.43-2.8-1.522-2.8c-1.515 0-2.145 1.089-2.145 2.8v5.106H9.708V9.388Zm-5.5 10.403h3.208V9.25H4.208v10.54ZM7.875 5.812a2.063 2.063 0 1 1-4.125 0a2.063 2.063 0 0 1 4.125 0Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            {/* {id !== "product-festive-gifting" && (
              <div className="col-md-4">
                <div className="image-section-custom">
                  <div className="image-container">
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/images/product-image.png`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )} */}

          </div>
        </div>
      </section>
      <Packaging id={id} />
      <section
        className={
          showform ? "section-form sec-form show-form" : "section-form sec-form"
        }
      >
        <div className="overlay" onClick={() => setShowform(false)}></div>
        <div className="order-banner">
          <h3>Do you want to order for your occasion?</h3>
          <button
            className="btn-main btn-order"
            onClick={() => setShowform(true)}
          >
            ORDER NOW
          </button>
        </div>
        <div className="container">
          <div className="close-icon" onClick={() => setShowform(false)}>
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.4998 2.9165C14.6155 2.9165 11.796 3.7718 9.39778 5.37424C6.99956 6.97668 5.13038 9.25428 4.0266 11.919C2.92282 14.5838 2.63402 17.516 3.19673 20.3449C3.75943 23.1738 5.14835 25.7723 7.18787 27.8118C9.22739 29.8513 11.8259 31.2403 14.6548 31.803C17.4837 32.3657 20.4159 32.0769 23.0806 30.9731C25.7454 29.8693 28.023 28.0001 29.6254 25.6019C31.2279 23.2037 32.0832 20.3841 32.0832 17.4998C32.0832 15.5847 31.706 13.6884 30.9731 11.919C30.2402 10.1497 29.166 8.54205 27.8118 7.18786C26.4576 5.83368 24.85 4.75948 23.0806 4.02659C21.3113 3.29371 19.415 2.9165 17.4998 2.9165ZM17.4998 29.1665C15.1924 29.1665 12.9368 28.4823 11.0182 27.2003C9.09962 25.9184 7.60427 24.0963 6.72125 21.9645C5.83823 19.8327 5.60719 17.4869 6.05735 15.2238C6.50751 12.9607 7.61865 10.8819 9.25026 9.25026C10.8819 7.61865 12.9607 6.5075 15.2238 6.05734C17.4869 5.60718 19.8327 5.83822 21.9645 6.72124C24.0963 7.60427 25.9184 9.09961 27.2003 11.0182C28.4823 12.9368 29.1665 15.1924 29.1665 17.4998C29.1665 20.594 27.9373 23.5615 25.7494 25.7494C23.5615 27.9373 20.594 29.1665 17.4998 29.1665Z"
                fill="#874F47"
              />
              <path
                d="M21.4522 13.5481C21.3167 13.4114 21.1554 13.3029 20.9777 13.2289C20.8 13.1548 20.6093 13.1167 20.4168 13.1167C20.2243 13.1167 20.0337 13.1548 19.856 13.2289C19.6783 13.3029 19.517 13.4114 19.3814 13.5481L17.5002 15.4439L15.6189 13.5481C15.3443 13.2735 14.9718 13.1192 14.5835 13.1192C14.1951 13.1192 13.8227 13.2735 13.5481 13.5481C13.2735 13.8227 13.1192 14.1951 13.1192 14.5835C13.1192 14.9718 13.2735 15.3443 13.5481 15.6189L15.4439 17.5002L13.5481 19.3814C13.4114 19.517 13.3029 19.6783 13.2289 19.856C13.1548 20.0337 13.1167 20.2243 13.1167 20.4168C13.1167 20.6093 13.1548 20.8 13.2289 20.9777C13.3029 21.1554 13.4114 21.3167 13.5481 21.4522C13.6836 21.5889 13.8449 21.6974 14.0226 21.7715C14.2004 21.8455 14.391 21.8836 14.5835 21.8836C14.776 21.8836 14.9666 21.8455 15.1443 21.7715C15.322 21.6974 15.4833 21.5889 15.6189 21.4522L17.5002 19.5564L19.3814 21.4522C19.517 21.5889 19.6783 21.6974 19.856 21.7715C20.0337 21.8455 20.2243 21.8836 20.4168 21.8836C20.6093 21.8836 20.8 21.8455 20.9777 21.7715C21.1554 21.6974 21.3167 21.5889 21.4522 21.4522C21.5889 21.3167 21.6974 21.1554 21.7715 20.9777C21.8455 20.8 21.8836 20.6093 21.8836 20.4168C21.8836 20.2243 21.8455 20.0337 21.7715 19.856C21.6974 19.6783 21.5889 19.517 21.4522 19.3814L19.5564 17.5002L21.4522 15.6189C21.5889 15.4833 21.6974 15.322 21.7715 15.1443C21.8455 14.9666 21.8836 14.776 21.8836 14.5835C21.8836 14.391 21.8455 14.2004 21.7715 14.0226C21.6974 13.8449 21.5889 13.6836 21.4522 13.5481Z"
                fill="#874F47"
              />
            </svg>
          </div>
          <div className="header-box underline">
            <h4>Write to us for your Customized Gifting</h4>
          </div>
          <form className="form-container" onSubmit={handleFormSubmit}>
            <div className="form-row">
              <div className="form-name">
                <input
                  type="text"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
              </div>
              <div className="form-phone">
                <input
                  type="text"
                  placeholder="Phone"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  required
                />
              </div>
            </div>
            <div className="form-email">
              <input
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </div>
            <div className="form-message">
              <textarea
                placeholder="Message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                required
              ></textarea>
            </div>
            <div className="form-submit">
              <div className="form-submit">
                <button
                  className="btn-main"
                  disabled={isPending ? true : false}
                >
                  {isPending ? "Submitting" : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <ShopModal />
    </main>
  );
}
