import React from "react";
import Slider from "react-slick";
import SlickArrow from "../../Components/SlickArrow";
/* import packageSlider1 from "../../images/packaging-slider-1.jpg";
import packageSlider2 from "../../images/packaging-slider-2.jpg";
import packageSlider3 from "../../images/packaging-slider-3.jpg";
import packageSlider4 from "../../images/packaging-slider-4.jpg"; */

export default function packaging({ id }) {
  // console.log(id);
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 2000,
    dots: true,
    arrows: true,
    infinite: true,
    prevArrow: <SlickArrow type="prev" />,
    nextArrow: <SlickArrow type="next" />,
  }
  return (
    <section className="section-packaging">
      <div className="text-center underline mb-4">
        <h2>Some Of Our Customised Gift Boxes</h2>
      </div>
      <div className="packaging-slider">
        {id === "product-corporate-boxes" && (
          <Slider {...settings}>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/corporate-gifting/corporate-1.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/corporate-gifting/corporate-2.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/corporate-gifting/corporate-3.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/corporate-gifting/corporate-4.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/corporate-gifting/corporate-5.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/corporate-gifting/corporate-6.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/corporate-gifting/corporate-7.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/corporate-gifting/corporate-8.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
          </Slider>
        )}
        {id === "product-festive-gifting" && (
          <Slider {...settings}>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/father-day/father-1.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-2.png`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
        
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-5.png`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-6.png`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-7.jpg`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/images/festive-slider-8.jpg`}
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
          </Slider>
        )}
        {id === "dashain-festive-gifting" && (
          <Slider {...settings}>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/dashain-festive/dashain-5.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/dashain-festive/dashain-4.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/dashain-festive/dashain-3.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/dashain-festive/dashain-2.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/dashain-festive/dashain-1.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
           
          </Slider>
        )}
         {id === "tihar-festive-gifting" && (
          <Slider {...settings}>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/tihar-festive/tihar-1.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/tihar-festive/tihar-2.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/tihar-festive/tihar-3.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/tihar-festive/tihar-4.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/tihar-festive/tihar-5.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                  src="/new-img/tihar-festive/tihar-6.jpg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
          </Slider>
        )}
         {id === "christmas-festive-gifting" && (
          <Slider {...settings}>
            <div className="content">
              <div className="image-container">
                <img
                  src="/images/chr1.jpeg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                     src="/images/chr2.jpeg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                     src="/images/chr3.jpeg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                     src="/images/chr4.jpeg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                    src="/images/chr5.jpeg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img
                     src="/images/chr6.jpeg"
                  alt="Makkusé Packaging"
                />
              </div>
            </div>
          
          </Slider>
        )}
      </div>
    </section>
  )
}
