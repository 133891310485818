import React, { useEffect } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import ShopModal from "../../Components/ShopModal";

const PustakariBlog = () => {
  useEffect(() => {
    document.title =
      "Pustakari: Traditional confection in its elements | Makkusé";
  }, []);

  const initialData = {
    secondayBanner: {
      bannerTitle: "Pustakari: Traditional confection in its elements",
      bannerDetail: "",
      bannerImg: `${process.env.REACT_APP_BASE_URL}/images/blogs/pustakri-blog-banner.jpg`,
      page: "",
    },
  };
  return (
    <main className="blogs">
      <SecondaryBanner bannerInfo={initialData.secondayBanner} />
      <section className="section-blog" style={{ marginBlock: "100px" }}>
        <div className="container">
          {/* <div className="sub-topic">
            <h2 style={{ fontWeight: "400" }}>
              There are so many exciting conversations waiting to happen within
              the Nepali dessert space – here are some thoughts from our team,
              clients and enthusiasts! Do have a read and leave your thoughts!
            </h2>
          </div> */}
          <div className="text-container">
            <div className="para">
              <p>
                पुष्टकारी ˈpusʈʌkaɾi is a traditional confection from Nepal that
                is created from Khuwa that has been boiled with ghee, milk,
                molasses and lemon extract.It is referred to in Nepali Bhasa as
                "postikan" .Its production has been significantly practiced in
                the Newari culture and they have mastered in the art of making
                Pustakari. According to Hindu rites and beliefs, Milk represents
                the creation, sanctification, and upkeep of the entire universe
                and it glorifies symbol of purity due to which every hindu
                ritual and ceremony includes milk as main element of "Puja" and
                "Havan".Given that Pustakari is also made from pure elements
                like ghee, sugar, and milk which is served as Prasad to gods and
                offered as Sagun on various occasions.
              </p>
              <p>
                Makkusé creates the most delicious hard candy, i.e Pustakari
                along with Gudpak and cookies, safeguarding the presence of
                "नेपालीपन", by bringing the best Khuwa and Milk from the
                outskirts of Banepa. Khuwa,traditionally made by simmering
                full-fat milk for many hours over a moderate fire in a big,
                shallow iron pan. Khuwa is classified into various kinds and
                used in a wide range of desserts and sweets .Khuwa, being a pure
                element in itself, it is offered as Sagun during rites and
                rituals,due to which it carries its own significance.
              </p>
              <p>
                Makkusé brings these delicacies in two sizes : The Regular
                Pustakari and The Handcrafted Pustakari. The Regular Pustakari
                Box weighs around 290 grams and contains roughly 25 pieces,
                depending on their sizes, whereas Handcraft Pustakari weighs
                around 370 grams and contains roughly 35 pieces, depending on
                their sizes. They are appropriately created and packaged while
                adhering to all production precautions. Our Pustakaris are
                packaged in custom-made eco-friendly boxes that may be recycled
                after its consumption, helping to save the environment.
              </p>
              <p>
                With an innovative touch to a recipe passed down from
                generations, Makkusé is sure to give you a bite of pure bliss.
              </p>
            </div>
            <div className="bottom-text">
              <p>Written By:</p>
              <p>Darshana Adhikari</p>
              <p>Business Development Officer</p>
              <p>Makkusé</p>
            </div>
          </div>
        </div>
      </section>
      <ShopModal />
    </main>
  );
};

export default PustakariBlog;
