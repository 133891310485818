import React from "react";
export default function SecondaryBanner(bannerInfo) {
  console.log("banner info", bannerInfo);
  return (
    <section
      className="section-secondary-banner"
      style={{
        backgroundImage:
          (bannerInfo.bannerInfo.page === "cookies" &&
            `linear-gradient(180deg,rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(${bannerInfo.bannerInfo.bannerImg})`) ||
          (bannerInfo.bannerInfo.page === "sagun" &&
            `linear-gradient(180deg,rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(${bannerInfo.bannerInfo.bannerImg})`) ||
          `url(${bannerInfo.bannerInfo.bannerImg})`,
        backgroundPosition: `${
          (bannerInfo.bannerInfo.page === "small-gift" && "50% 80%") ||
          (bannerInfo.bannerInfo.page === "sugarfree" && "50% 80%") ||
          (bannerInfo.bannerInfo.page === "ourStory" && "50% 30%") ||
          (bannerInfo.bannerInfo.page === "all-product" && "50% 78%") ||
          (bannerInfo.bannerInfo.page === "combo-boxes" && "50% 78%") ||
          (bannerInfo.bannerInfo.page === "sagun" && "50% 65%") ||
          (!bannerInfo.bannerInfo.page && "center")
        }`,
      }}
    >
      <div className="banner--text-container">
        <div className="header-box">
          <h1>{bannerInfo.bannerInfo.bannerTitle}</h1>
        </div>
        <div
          className="sub-head"
          style={{
            maxWidth: `${bannerInfo.bannerInfo.page === "ourStory" && "100%"}`,
          }}
        >
          <h5>{bannerInfo.bannerInfo.bannerDetail}</h5>
        </div>
      </div>
    </section>
  );
}
