import React, { useState } from "react";
import phoneImg from "../images/call.svg";
import messengerImg from "../images/messenger.svg";
import instaImg from "../images/insta.svg";
import gyapuIcon from "../images/Icon_Gyapu.png";
import munchaIcon from "../images/Icon_muncha.png";
import darazIcon from "../images/daraz-icon.png";
import hamroPatroIcon from "../images/hamro-patro-icon.png";
import bizbazarIcon from "../images/bizbazar.png";
import smartDokoIcon from "../images/smartdoko.png";
import giftmanduIcon from "../images/giftmandu.png";
import tavisiIcon from "../images/tavisi-icon.png";

const ShopModal = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <section className={`shop-now ${showModal ? "show" : ""}`}>
      <div className="shop-icon">
        <p className="extra-small">Shop Now</p>
        <svg
          width="80"
          height="80"
          viewBox="0 0 98 98"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(true);
          }}
        >
          <g filter="url(#filter0_d_2_869)">
            <circle cx="52" cy="47" r="40" fill="#303030" />
          </g>
          <path
            d="M52 26C48.4364 26 46.0505 27.6025 44.9827 29.6847C43.9166 31.7685 43.9231 34.0495 43.9231 35.6923C43.9231 36.1207 44.0932 36.5316 44.3962 36.8346C44.6991 37.1375 45.11 37.3077 45.5384 37.3077C45.9669 37.3077 46.3777 37.1375 46.6807 36.8346C46.9836 36.5316 47.1538 36.1207 47.1538 35.6923C47.1538 34.1044 47.2717 32.3485 47.8614 31.1999C48.4494 30.0514 49.2635 29.2308 52 29.2308C54.7219 29.2308 55.5441 30.074 56.1402 31.25C56.7331 32.426 56.8461 34.1868 56.8461 35.6923C56.8461 36.1207 57.0163 36.5316 57.3193 36.8346C57.6222 37.1375 58.0331 37.3077 58.4615 37.3077C58.8899 37.3077 59.3008 37.1375 59.6038 36.8346C59.9067 36.5316 60.0769 36.1207 60.0769 35.6923C60.0769 34.1286 60.0769 31.88 59.0172 29.7865C57.9559 27.6913 55.5781 26 52 26ZM37.7636 38.9231C37.1174 38.9231 36.5117 39.4287 36.3501 40.2364L31.3037 65.879C31.1405 67.0098 31.6461 68 32.6154 68H71.3846C72.3538 68 72.8594 67.0098 72.6979 65.879L67.6498 40.2364C67.4883 39.4287 66.8809 38.9247 66.2364 38.9247H37.7652L37.7636 38.9231ZM44.7307 40.5385C46.0231 40.5385 47.1538 41.6692 47.1538 42.9615C47.1538 44.2538 46.0231 45.3846 44.7307 45.3846C43.4384 45.3846 42.3077 44.2538 42.3077 42.9615C42.3077 41.6692 43.4384 40.5385 44.7307 40.5385ZM59.2692 40.5385C60.5615 40.5385 61.6923 41.6692 61.6923 42.9615C61.6923 44.2538 60.5615 45.3846 59.2692 45.3846C57.9769 45.3846 56.8461 44.2538 56.8461 42.9615C56.8461 41.6692 57.9769 40.5385 59.2692 40.5385Z"
            fill="#070606"
          />
          <defs>
            <filter
              id="filter0_d_2_869"
              x="0"
              y="0"
              width="98"
              height="98"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feMorphology
                radius="1"
                operator="dilate"
                in="SourceAlpha"
                result="effect1_dropShadow_2_869"
              />
              <feOffset dx="-3" dy="2" />
              <feGaussianBlur stdDeviation="4" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_2_869"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_2_869"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div
        className="overlay"
        onClick={() => {
          setShowModal(false);
        }}
      ></div>
      <div className="shop-now-modal" onClick={(e) => e.stopPropagation()}>
        <div className="header-box">
          <h4>E-commerce Distributors</h4>
        </div>
        <div className="sub-head">
          <p className="small-text">
            Our products are available in these platforms.
          </p>
        </div>
        <div className="content-wrapper">
          {/* <div className="shop-contact"> */}
          {/*   <a href="tel:+977 9862265754" rel="noreferrer"> */}
          {/*     <div className="content"> */}
          {/*       <div className="icon-container"> */}
          {/*         <img src={phoneImg} alt="Call" /> */}
          {/*       </div> */}
          {/*       <p className="topic-regular shop-call">Call</p> */}
          {/*     </div> */}
          {/*   </a> */}
          {/*   <a href="https://m.me/makkuse.np" target="_blank" rel="noreferrer"> */}
          {/*     <div className="content"> */}
          {/*       <div className="icon-container"> */}
          {/*         <img src={messengerImg} alt="Messenger" /> */}
          {/*       </div> */}
          {/*       <p className="topic-regular">Messenger</p> */}
          {/*     </div> */}
          {/*   </a> */}
          {/*   <a */}
          {/*     href="https://www.instagram.com/makkuse.np/" */}
          {/*     target="_blank" */}
          {/*     rel="noreferrer" */}
          {/*   > */}
          {/*     <div className="content"> */}
          {/*       <div className="icon-container"> */}
          {/*         <img src={instaImg} alt="instagram" /> */}
          {/*       </div> */}
          {/*       <p className="topic-regular">Instagram</p> */}
          {/*     </div> */}
          {/*   </a> */}
          {/* </div> */}
          <div className="vendor-wrapper">
            {/* <p className="small-text">For International Payment</p> */}
            <div className="button-wrapper">
              <div className="button-container">
                <a
                  href="https://www.daraz.com.np/catalog/?q=makkuse&_keyori=ss&from=input&spm=a2a0e.11779170.search.go.287d2d2bZPvSrs"
                  target="blank"
                >
                  <div className="image-container">
                    <img src={darazIcon} alt="Daraz" />
                  </div>
                </a>
              </div>
              <div className="button-container">
                <a href="https://www.gyapu.com/search/makkuse" target="blank">
                  <div className="image-container">
                    <img src={gyapuIcon} alt="Gyapu" />
                  </div>
                </a>
              </div>
              <div className="button-container">
                <a
                  href="https://gifts.hamropatro.com/s?q=makkuse"
                  target="blank"
                >
                  <div className="image-container">
                    <img
                      src={hamroPatroIcon}
                      alt="Hamro Patro"
                      style={{ objectFit: "contain", height: "20px" }}
                    />
                  </div>
                </a>
              </div>
              <div className="button-container">
                <a
                  href="https://muncha.com/Shop/Search?merchantID=1&CategoryID=0&q=makkuse"
                  target="blank"
                >
                  <div className="image-container">
                    <img
                      src={bizbazarIcon}
                      alt="Bizbazar"
                      style={{ width: "60px" }}
                    />
                  </div>
                </a>
              </div>
              <div className="button-container">
                <a
                  href="https://muncha.com/Shop/Search?merchantID=1&CategoryID=0&q=makkuse"
                  target="blank"
                >
                  <div className="image-container">
                    <img src={munchaIcon} alt="Muncha" />
                  </div>
                </a>
              </div>
              <div className="button-container">
                <a
                  href="https://smartdoko.com/search?category=all&q=makkuse&device=desktop"
                  target="blank"
                >
                  <div className="image-container">
                    <img
                      src={smartDokoIcon}
                      alt="Smart Doko"
                      style={{ width: "60px" }}
                    />
                  </div>
                </a>
              </div>
              <div className="button-container">
                <a
                  href="https://www.giftmandu.com/?target=search&mode=search&substring=makkuse&including=all"
                  target="blank"
                >
                  <div className="image-container">
                    <img
                      src={giftmanduIcon}
                      alt="Giftmandu"
                      style={{ aspectRatio: "115/40", width: "60px" }}
                    />
                  </div>
                </a>
              </div>
              <div className="button-container">
                <a
                  href="https://www.tavisinepal.com/product?name=Makkuse"
                  target="blank"
                >
                  <div className="image-container">
                    <img src={tavisiIcon} alt="Tavisi" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/*  <div className="column">
          <div className="header-box">
            <h4>Order Now</h4>
          </div>
          <div className="shop-contact">
            <a href="tel:+977 9862265754" rel="noreferrer">
              <div className="content">
                <div className="icon-container">
                  <img src={phoneImg} alt="Call" />
                </div>
                <p
                  className="topic-regular"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="+977 9862265754"
                >
                  Call
                </p>
              </div>
            </a>
            <a href="https://m.me/makkuse.np" target="_blank" rel="noreferrer">
              <div className="content">
                <div className="icon-container">
                  <img src={messengerImg} alt="Messenger" />
                </div>
                <p className="topic-regular">Messenger</p>
              </div>
            </a>
            <a
              href="https://www.instagram.com/makkuse.np/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="content">
                <div className="icon-container">
                  <img src={instaImg} alt="instagram" />
                </div>
                <p className="topic-regular">Instagram</p>
              </div>
            </a>
          </div>
          <p className="extra-small">Delivery Inside Valley Only</p>
        </div>
        <div className="column">
          <div className="header-box">
            <h4>Buy Online</h4>
            <p className="extra-small">International Payment</p>
          </div>
          <div className="button-container">
            <a
              href="https://www.gyapu.com/detail/makkuse-mocha-gundpak"
              target="blank"
            >
              <div className="image-container">
                <img src={gyapuIcon} alt="Gyapu" />
              </div>
            </a>
            <p className="extra-small" style={{ marginTop: "5px" }}>
              Delivery Outside Valley
            </p>
          </div>
          <div className="button-container">
            <a
              href="https://muncha.com/88212/I/Makkus-Mocha-Gundpak-200-g?mid=1&cid=14524"
              target="blank"
            >
              <div className="image-container">
                <img src={munchaIcon} alt="Muncha" />
              </div>
            </a>
          </div>
        </div> */}
        <div
          className="close-icon close-icons"
          onClick={(e) => {
            e.stopPropagation();
            setShowModal(false);
          }}
        >
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4998 2.9165C14.6155 2.9165 11.796 3.7718 9.39778 5.37424C6.99956 6.97668 5.13038 9.25428 4.0266 11.919C2.92282 14.5838 2.63402 17.516 3.19673 20.3449C3.75943 23.1738 5.14835 25.7723 7.18787 27.8118C9.22739 29.8513 11.8259 31.2403 14.6548 31.803C17.4837 32.3657 20.4159 32.0769 23.0806 30.9731C25.7454 29.8693 28.023 28.0001 29.6254 25.6019C31.2279 23.2037 32.0832 20.3841 32.0832 17.4998C32.0832 15.5847 31.706 13.6884 30.9731 11.919C30.2402 10.1497 29.166 8.54205 27.8118 7.18786C26.4576 5.83368 24.85 4.75948 23.0806 4.02659C21.3113 3.29371 19.415 2.9165 17.4998 2.9165ZM17.4998 29.1665C15.1924 29.1665 12.9368 28.4823 11.0182 27.2003C9.09962 25.9184 7.60427 24.0963 6.72125 21.9645C5.83823 19.8327 5.60719 17.4869 6.05735 15.2238C6.50751 12.9607 7.61865 10.8819 9.25026 9.25026C10.8819 7.61865 12.9607 6.5075 15.2238 6.05734C17.4869 5.60718 19.8327 5.83822 21.9645 6.72124C24.0963 7.60427 25.9184 9.09961 27.2003 11.0182C28.4823 12.9368 29.1665 15.1924 29.1665 17.4998C29.1665 20.594 27.9373 23.5615 25.7494 25.7494C23.5615 27.9373 20.594 29.1665 17.4998 29.1665Z"
              fill="#874F47"
            />
            <path
              d="M21.4522 13.5481C21.3167 13.4114 21.1554 13.3029 20.9777 13.2289C20.8 13.1548 20.6093 13.1167 20.4168 13.1167C20.2243 13.1167 20.0337 13.1548 19.856 13.2289C19.6783 13.3029 19.517 13.4114 19.3814 13.5481L17.5002 15.4439L15.6189 13.5481C15.3443 13.2735 14.9718 13.1192 14.5835 13.1192C14.1951 13.1192 13.8227 13.2735 13.5481 13.5481C13.2735 13.8227 13.1192 14.1951 13.1192 14.5835C13.1192 14.9718 13.2735 15.3443 13.5481 15.6189L15.4439 17.5002L13.5481 19.3814C13.4114 19.517 13.3029 19.6783 13.2289 19.856C13.1548 20.0337 13.1167 20.2243 13.1167 20.4168C13.1167 20.6093 13.1548 20.8 13.2289 20.9777C13.3029 21.1554 13.4114 21.3167 13.5481 21.4522C13.6836 21.5889 13.8449 21.6974 14.0226 21.7715C14.2004 21.8455 14.391 21.8836 14.5835 21.8836C14.776 21.8836 14.9666 21.8455 15.1443 21.7715C15.322 21.6974 15.4833 21.5889 15.6189 21.4522L17.5002 19.5564L19.3814 21.4522C19.517 21.5889 19.6783 21.6974 19.856 21.7715C20.0337 21.8455 20.2243 21.8836 20.4168 21.8836C20.6093 21.8836 20.8 21.8455 20.9777 21.7715C21.1554 21.6974 21.3167 21.5889 21.4522 21.4522C21.5889 21.3167 21.6974 21.1554 21.7715 20.9777C21.8455 20.8 21.8836 20.6093 21.8836 20.4168C21.8836 20.2243 21.8455 20.0337 21.7715 19.856C21.6974 19.6783 21.5889 19.517 21.4522 19.3814L19.5564 17.5002L21.4522 15.6189C21.5889 15.4833 21.6974 15.322 21.7715 15.1443C21.8455 14.9666 21.8836 14.776 21.8836 14.5835C21.8836 14.391 21.8455 14.2004 21.7715 14.0226C21.6974 13.8449 21.5889 13.6836 21.4522 13.5481Z"
              fill="#874F47"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default ShopModal;
