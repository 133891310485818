import axios from "axios";
import React, { useEffect, useState } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import ShopModal from "../../Components/ShopModal";
import ourstoryBanner from "../../images/banner-image.png";

export default function Contact() {
  useEffect(() => {
    document.title = "Get in Touch with Makkuse";
  }, []);
  const initialData = {
    secondayBanner: {
      bannerTitle: "Contact",
      bannerDetail: "",
      bannerImg: ourstoryBanner,
    },
  };
  const [submitCount, setSubmitCount] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (modalShow) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [modalShow]);
  useEffect(() => {
    if (submitCount) {
      async function sendMail() {
        try {
          // makkuseOrders
          const response = await axios.post(
            "https://api.uniboard.unitechmedia.com.np/api/makkuseInquiries",
            {
              name: name,
              phone: phone,
              email: email,
              message: message,
            }
          );
          if (response.data) {
            setSubmitCount(0);
            setIsPending(false);
            setEmail("");
            setName("");
            setPhone("");
            setMessage("");
          }
          // console.log(response);
        } catch (error) {
          console.log(error);
        }
      }
      sendMail();
    }
  }, [submitCount]);
  function handleFormSubmit(e) {
    console.log("submitted");
    e.preventDefault();
    setIsPending(true);
    setSubmitCount((prevCount) => prevCount + 1);
  }

  return (
    <main className="page-contact">
      <SecondaryBanner bannerInfo={initialData.secondayBanner} />
      <section className="section-contact-text">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <div className="header-box underline">
                  <h4>Stay in touch</h4>
                </div>
                <div className="contact-wrapper">
                  <div className="contact-container">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#874F47"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-mail"
                      >
                        <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                        <polyline points="22,6 12,13 2,6"></polyline>
                      </svg>
                    </div>
                    <div className="text-container">
                      <a className="another-a" href="mailto:info@makkuse.com">
                        info@makkuse.com
                      </a>
                    </div>
                  </div>
                  <div className="contact-container">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#874F47"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-phone"
                      >
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                      </svg>
                    </div>
                    <div className="text-container">
                      <a className="another-a" href="tel:+9779820106801">
                        +977 9820106801
                      </a>
                    </div>
                  </div>
                  <div className="contact-container">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#874F47"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-phone"
                      >
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                      </svg>
                    </div>
                    <div className="text-container">
                      <a className="another-a" href="tel:+9779820106916">
                        +977 9820106916
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper">
                <div className="dark-header underline">
                  <h4>Your reviews and comments are welcome</h4>
                </div>
                <div className="button-container">
                  <a
                    href="https://www.instagram.com/makkuse.np/"
                    target="blank"
                  >
                    <div className="bt1">
                      <button className="btn-main">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          width="17"
                          height="17"
                          fill="white"
                        >
                          <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                        </svg>
                        makkuse.np
                      </button>
                    </div>
                  </a>
                  <a
                    href="https://www.facebook.com/makkuse.np"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="bt1">
                      <button className="btn-main">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                          width="8.82"
                          height="17"
                          fill="white"
                        >
                          <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                        </svg>
                        makkuse.np
                      </button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.0636000712047!2d85.30767517524035!3d27.715322525140014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb190b547a1c09%3A0x10fec5d48318eb98!2sMakkus%C3%A9%20Desserts%20Bar!5e0!3m2!1sen!2snp!4v1699178851229!5m2!1sen!2snp"
                  width="100%"
                  style={{ border: "0", aspectRatio: "4/3", maxWidth: "500px" }}
                  allowfullscreen=""
                  loading="lazy"
                  title="makkuse"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
                <div className="contact-desert d-flex justify-content-center mt-4 gap-2">
                    <div className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#874F47"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-phone"
                      >
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                      </svg>
                    </div>
                    <div className="text-container">
                      <a className="another-a" href="tel:+977982-8160548">
                        +977 982-8160548
                      </a>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-form">
        <div className="container">
          <div className="header-box underline">
            <h4>If you prefer to send us message</h4>
          </div>
          <div className="form-container">
            <form className="form" id="myform" onSubmit={handleFormSubmit}>
              <input
                type="hidden"
                name="_token"
                value="Fo0PuqV8cNHs2RNwVk0k1siD5YPKzkLSXpsGCZjG"
              />
              <div className="form-row">
                <div className="form-name">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
                <div className="form-phone">
                  <input
                    type="text"
                    placeholder="Phone"
                    name="phone"
                    required
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </div>
              </div>
              <div className="form-email">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="form-message">
                <textarea
                  placeholder="Message"
                  name="message"
                  required
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                ></textarea>
              </div>
              <div className="form-submit">
                <button
                  className="btn-main"
                  disabled={isPending ? true : false}
                >
                  {isPending ? "Submitting" : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          className={modalShow ? "modal fade show" : "modal fade"}
          style={modalShow ? { display: "block" } : { display: "none" }}
          id="getCodeModal"
          tabindex="-1"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModalShow(false)}
                ></button>
              </div>
              <div className="modal-body" id="getCode">
                Thank you for your feed back. We will repond to your inquiry
                asap.
                {/* <!--//ajax success content here.--> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ShopModal />
    </main>
  );
}
