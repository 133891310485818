import React, { useState } from "react";
import { Link } from "react-router-dom";
/* import comboPack from "../images/combo-banner-thumb.jpg";
import luxuryBox from "../images/makkuse-lux.jpeg";
import smallGift from "../images/small-box-thumb-banner.jpg"; */
const OtherSpecial = () => {
  const [showProduct, setShowProduct] = useState(false);
  return (
    <div className="other-products">
      <div className="header-box">
        <h2>Other Specials</h2>
      </div>
      <div
        className="down-arrow"
        style={{ transform: "rotate(180deg)" }}
        onClick={() => setShowProduct(!showProduct)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="44"
          height="44"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#874F47"
          strokeWidth="2"
          strokeLineCap="round"
          strokeLineJoin="round"
          className="feather feather-arrow-up-circle"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <polyline points="16 12 12 8 8 12"></polyline>
          <line x1="12" y1="16" x2="12" y2="8"></line>
        </svg>
      </div>

      <div className={`product-container ${showProduct && "show"}`}>
        <Link to="/product-combo-pack">
          <div className="card">
            <div className="image-container">
              <div className="overlay"></div>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/combo-banner-thumb.jpg`}
                alt="Combo Pack"
              />
            </div>
            <div className="text-container">
              <div className="card--title">
                <p className="">Special Packs</p>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/product-luxury-box">
          <div className="card">
            <div className="image-container">
              <div className="overlay"></div>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/makkuse-lux.jpeg`}
                alt="Luxury Box"
              />
            </div>
            <div className="text-container">
              <div className="card--title">
                <p className="">The Ultimate Luxury Box</p>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/product-small-gift-box">
          <div className="card">
            <div className="image-container">
              <div className="overlay"></div>
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/small-box-thumb-banner.jpg`}
                alt="Small Gift Box"
              />
            </div>
            <div className="text-container">
              <div className="card--title">
                <p className="">Small Gift Box</p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default OtherSpecial;
