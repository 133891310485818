import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
/* import bannerSliderImage1 from "../../images/banner-slider1.png";
import bannerSliderImage2 from "../../images/banner-slider2.png";
import bannerSliderImage3 from "../../images/banner-slider3.png"; */

import Slider from "react-slick";
// import { Distributor } from "./Distributor";

export default function BannerSlider({ setOrderModal }) {
  const [showModal, setShowModal] = useState(false);
  // const [orderModal, setOrderModal] = useState(false);

  useEffect(() => {
    const shopNow = document.querySelector(".shop-now");
    if (showModal) {
      shopNow.classList.add("show");
    } else {
      shopNow.classList.remove("show");
    }
    window.addEventListener("click", () => setShowModal(false));
    window.addEventListener("click", () => setOrderModal(false));
    document
      .querySelector(".close-icons")
      .addEventListener("click", () => setShowModal(false));
  }, [showModal]);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 3000,
    dots: true,
    infinite: true,
    arrows: false,
  };
  return (
    <section className="section-banner">
      <div className="banner-container ">
        <div className="banner-parent relative">
          <Slider {...settings} className="">
            <div className="content">
              <div className="image-container">
                <img src="/images/banner-slider1.png" alt="cylindrical christmas gift box" />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img src="/images/banner-slider2.png" alt="winter gift box" />
              </div>
            </div>
            <div className="content">
              <div className="image-container">
                <img src="/images/banner-slider3.png" alt="large christmas box" />
              </div>
            </div>
          </Slider>
        
        <div className="banner-center transform -translate-x-1/2 -translate-y-1/2 text-center ">
            <h1 className="text-3xl font-bold">Nepali Desserts Redefined</h1>
            <p className="mt-2">
              Sharing the authentic luxury desserts recipes from the Himalayas
            </p>
        </div>
        </div>
      </div>{" "}
      <div className="section-info-banner">
        {" "}
        <div className="banner-content">
          {" "}
          <div className="image-container">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              height="30px"
              width="33px"
            >
              {/* <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
              <path d="M512 165.4c0 127.9-70.05 235.3-175.3 270.1c-20.04 7.938-41.83 12.46-64.69 12.46c-64.9 0-125.2-36.51-155.7-94.47c-54.13 49.93-68.71 107-68.96 108.1C44.72 472.6 34.87 480 24.02 480c-1.844 0-3.727-.2187-5.602-.6562c-12.89-3.098-20.84-16.08-17.75-28.96c9.598-39.5 90.47-226.4 335.3-226.4C344.8 224 352 216.8 352 208S344.8 192 336 192C228.6 192 151 226.6 96.29 267.6c.1934-10.82 1.242-21.84 3.535-33.05c13.47-65.81 66.04-119 131.4-134.2c28.33-6.562 55.68-6.013 80.93-.0054c56 13.32 118.2-7.412 149.3-61.24c5.664-9.828 20.02-9.516 24.66 .8282C502.7 76.76 512 121.9 512 165.4z" />
            </svg>
          </div>
          <div className="text-container">
            <div className="header-box">
              <p className="sub-title">100% Pure Milk</p>
            </div>
            <div className="para">
              <p className="small-text-white">
                Our Khuwa (thickened milk) arrives fresh from the high altitudes
                of Kavre, Nepal daily to our production center located at
                Kathmandu.
              </p>
            </div>
          </div>
        </div>
        <div className="banner-content">
          <div className="image-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              width="22.5"
              height="34"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 512 512"
            >
              <path
                fill="#874f47"
                d="M382 0H130a18 18 0 0 0-18 18v476a18 18 0 0 0 18 18h252a18 18 0 0 0 18-18V18a18 18 0 0 0-18-18ZM148 448V64h216v384Z"
              />
            </svg>
          </div>
          <div className="text-container">
            <div className="header-box">
              <p
                className="sub-title"
                onClick={(e) => {
                  e.stopPropagation();
                  setOrderModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Order Now
              </p>
            </div>
            <div className="para">
              <p className="small-text-white">
                Happily Delivering these treats to you, Text or Call us on these
                platforms for orders and queries.
              </p>
            </div>
          </div>
          {/* {showModal && ( <div className="overlay" onClick={() => setShowModal(false)}></div>)} <Distributor showModal={showModal} setShowModal={setShowModal} /> */}
        </div>
        <div className="banner-content">
          <div className="image-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#874f47"
                d="M9.06 1.93C7.17 1.92 5.33 3.74 6.17 6H3a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h9V8h2v3h9a1 1 0 0 0 1-1V8a2 2 0 0 0-2-2h-3.17C19 2.73 14.6.42 12.57 3.24L12 4l-.57-.78c-.63-.89-1.5-1.28-2.37-1.29M9 4c.89 0 1.34 1.08.71 1.71C9.08 6.34 8 5.89 8 5a1 1 0 0 1 1-1m6 0c.89 0 1.34 1.08.71 1.71c-.63.63-1.71.18-1.71-.71a1 1 0 0 1 1-1M2 12v8a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-8h-9v8h-2v-8H2Z"
              />
            </svg>
          </div>
          <div className="text-container">
            <div className="header-box">
              <p
                className="sub-title"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                Send a Gift to Nepal
              </p>
            </div>
            <div className="para">
              <p className="small-text-white">
                We are available on these e-commerce sites. We accept
                international card payments, e-wallets, and cash on delivery.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
