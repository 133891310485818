import React, { useEffect } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import StoryDetail from "./StoryDetail";
import ProductionProcess from "./ProductionProcess";
import MakkuseMedia from "./MakkuseMedia";
import ShopModal from "../../Components/ShopModal";
// import ourstoryBanner from "../../images/our-story-banner.jpg";
export default function OurStory() {
  useEffect(() => {
    document.title = "Our Story | Makkusé";
  }, []);
  const initialData = {
    secondayBanner: {
      bannerTitle: "Preserving, Promoting, and Innovating",
      bannerDetail: "Authentic Nepali Dessert Recipes",
      bannerImg: `${process.env.REACT_APP_BASE_URL}/images/our-story-banner.jpg`,
      page: "ourStory",
    },
  };

  return (
    <main class="page-about-us">
      <SecondaryBanner bannerInfo={initialData.secondayBanner} />;
      <StoryDetail />
      <ProductionProcess />
      <MakkuseMedia />
      <ShopModal />
    </main>
  );
}
