import React from "react";
import { Link } from "react-router-dom";
/* import brassSpoon from "../../images/brass-spoon.png";
import loktaPaperBenifits from "../../images/lokta-paper-benifits.png";
import story from "../../images/story.png"; */
export default function ProductionProcess() {
  return (
    <section className="section-production-process">
      <div className="container">
        <div className="row order">
          <div className="sec-row">
            <div className="text-container odd">
              <div className="header-box underline">
                <h2>Significance of Copper Spoon</h2>
              </div>
              <div className="para">
                <p>
                  You will see copper hints throughout our branding, an ode to
                  the unique copper elements that are an integral part of
                  ceremonies and occasions celebrated within the Newar and the
                  wider Nepali community, especially in the signature
                  handcrafted copper spoon.
                </p>
              </div>
            </div>
            <div className="image-container odd">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/brass-spoon.png`}
                alt="Myth of brass spoon"
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
          <div className="sec-row">
            <div className="text-container even">
              <div className="header-box underline">
                <h2>Lokta paper benefits</h2>
              </div>
              <div className="para">
                <p>
                  Artisans from the heart of Himalayas in Nepal, render a
                  traditional process of ancient paper making with modern
                  fashion and style to make the Lokta paper. The Lokta paper is
                  characterized by high paper quality, prized with superior
                  durability and rich texture. Our packages are reusable for
                  decorational and storage purposes.
                </p>
              </div>
            </div>
            <div className="image-container even">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/lokta-paper-benifits.png`}
                alt=""
              />
            </div>
          </div>
          <div className="sec-row">
            <div className="text-container odd">
              <div className="header-box underline">
                <h2>Makkusé Blogs</h2>
              </div>
              <div className="para">
                <p>
                  There are so many exciting conversations waiting to happen
                  within the Nepali dessert space – here are some thoughts from
                  our team, clients and enthusiasts! Do have a read and leave
                  your thoughts!
                </p>
                <Link
                  to="/blog"
                  className="read-more-link"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="image-container odd">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/story.png`}
                alt=""
              />
            </div>
          </div>
          <div className="sec-row">
            <div className="text-container odd">
              <div className="header-box underline">
                <h2>The Nepali Sagun</h2>
              </div>
              <div className="para">
                <p>
                  Sagun: /səˈgun/ सगुन , is a polite and original cultural
                  practice widely followed in the Hindu culture and also
                  significantly practiced in the Newari culture too. Sagun is a
                  word taken from Sanskrit, which simply means auspicious. The
                  origin and culture of Sagun bases itself on the Vedic
                  Scriptures and Hindu textbooks.
                </p>
                <Link
                  to="/blog/nepali-sagun"
                  className="read-more-link"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="image-container even">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/blog-2.png`}
                alt=""
              />
            </div>
          </div>
          <div className="sec-row">
            <div className="text-container odd">
              <div className="header-box underline">
                <h2>Pustakari: Traditional confection in its elements</h2>
              </div>
              <div className="para">
                <p>
                  पुष्टकारी ˈpusʈʌkaɾi is a traditional confection from Nepal
                  that is created from Khuwa that has been boiled with ghee,
                  milk, molasses and lemon extract.It is referred to in Nepali
                  Bhasa as "postikan" .Its production has been significantly
                  practiced in the Newari culture and they have mastered in the
                  art of making Pustakari.
                </p>
                <Link
                  to="/blog/pustakari-confection"
                  className="read-more-link"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="image-container even">
              <img
                src="/new-img/father-day/pustakari.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="sec-row">
            <div className="text-container odd">
              <div className="header-box underline">
                <h2>Celebrating Lhosar with Makkusé</h2>
              </div>
              <div className="para">
                <p>
                  Nepal, a country home to diverse ethnicities, rich culture and
                  vibrant festivals. One among many celebrations, Lhosar
                  (Tibetan: ལོ་གསར་, Wylie: lo-gsar; Lo/Lho, meaning Year, and
                  Sar/Chhar, New) is one of the biggest festivals celebrated by
                  the Sherpa, Tamang, Tibetan, Hyolmo and Gurung communities in
                  Nepal and some parts of India. Historically a harvest festival
                  that is now a grandeur of celebration marking the New Year.
                </p>
                <Link
                  to="/blog/lhosar"
                  className="read-more-link"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  Read More
                </Link>
              </div>
            </div>
            <div className="image-container even">
              <img
                src="/new-img/father-day/lhosar.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
