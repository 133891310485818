import React, { useEffect, useState } from "react";
/* import gallery1 from "../../images/gallery-1.png";
import gallery2 from "../../images/gallery-2.png";
import gallery6 from "../../images/gallery-6.png";
import gallery7 from "../../images/gallery-7.png";
import gallery10 from "../../images/gallery-10.jpg";
import gallery11 from "../../images/gallery-11.jpg";
import gallery12 from "../../images/gallery-12.jpg";
import gallery13 from "../../images/gallery-13.jpg"; */

import playCircleRegular from "../../images/play-circle-regular.svg";
import closeCircle from "../../images/eva_close-circle-outline.png";
import next from "../../images/next.png";
import prev from "../../images/prev.png";

export default function GalleryContainer() {
  const [photo, setPhoto] = useState(true);
  const [video, setVideo] = useState(false);
  const [showPhotoModal, setshowPhotoModal] = useState(false);
  const [imageId, setimageId] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoLink, setVideoLink] = useState(null);

  const initialData = {
    photoCollection: [
      {
        id: 1,
        image: `${process.env.REACT_APP_BASE_URL}/images/gallery-1.png`,
      },
      {
        id: 2,
        image: `${process.env.REACT_APP_BASE_URL}/images/gallery-2.png`,
      },
      {
        id: 3,
        image: `${process.env.REACT_APP_BASE_URL}/images/gallery-6.png`,
      },
      {
        id: 4,
        image: `${process.env.REACT_APP_BASE_URL}/images/gallery-7.png`,
      },
      {
        id: 5,
        image: `${process.env.REACT_APP_BASE_URL}/images/gallery-10.jpg`,
      },
      {
        id: 6,
        image: `${process.env.REACT_APP_BASE_URL}/images/gallery-11.jpg`,
      },
      {
        id: 7,
        image: `${process.env.REACT_APP_BASE_URL}/images/gallery-12.jpg`,
      },
      {
        id: 8,
        image: `${process.env.REACT_APP_BASE_URL}/images/gallery-13.jpg`,
      },
    ],
    videoCollection: [
      {
        id: 1,
        video: "https://www.youtube.com/embed/MjFFgUUffGs",
        image: "https://img.youtube.com/vi/MjFFgUUffGs/hqdefault.jpg",
      },
      {
        id: 2,
        video: "https://www.youtube.com/embed/wclfjHrs0zc",
        image: "https://img.youtube.com/vi/wclfjHrs0zc/hqdefault.jpg",
      },
      {
        id: 3,
        video: "https://www.youtube.com/embed/fFk8mOfeC8g",
        image: "https://img.youtube.com/vi/fFk8mOfeC8g/hqdefault.jpg",
      },
      {
        id: 4,
        video: "https://www.youtube.com/embed/tnztl231Gj4",
        image: "https://img.youtube.com/vi/tnztl231Gj4/hqdefault.jpg",
      },
      {
        id: 5,
        video: "https://www.youtube.com/embed/hc9OQI59c98",
        image: "https://img.youtube.com/vi/hc9OQI59c98/hqdefault.jpg",
      },
      {
        id: 6,
        video: "https://www.youtube.com/embed/JhUpDQ6XBdM",
        image: "https://img.youtube.com/vi/JhUpDQ6XBdM/hqdefault.jpg",
      },
    ],
  };
  const handlePhoto = () => {
    setPhoto(true);
    setVideo(false);
  };
  const handleClickPhoto = (id) => {
    setimageId(id);
    setshowPhotoModal(true);
  };
  const handleClickVideo = (link) => {
    setVideoLink(link);
    setShowVideoModal(true);
  };
  const handleVideo = () => {
    setPhoto(false);
    setVideo(true);
  };
  const handleVideoClose = () => {
    setShowVideoModal(false);
    setVideoLink(null);
  };

  useEffect(() => {
    if (showPhotoModal) {
      document.body.classList.add("modal-opened");
    } else {
      document.body.classList.remove("modal-opened");
    }
    if (showVideoModal) {
      document.body.classList.add("modal-opened-video");
    } else {
      document.body.classList.remove("modal-opened-video");
    }
  }, [showPhotoModal, showVideoModal]);
  return (
    <section className="section-gallery-container">
      <div className="container">
        <div className="sub-topic">
          <p className="topic-regular">
            Experience the traditional past, a playful present and an innovative
            past with our range of Pustakari, Gundpak and Gundpak filled
            Cookies.
          </p>
        </div>
        <div className="sortButtom">
          <div className={photo ? "photo-filer active" : "photo-filer"}>
            <p onClick={handlePhoto}>Photo</p>
          </div>
          <div className={video ? "video-filer active" : "video-filer"}>
            <p onClick={handleVideo}>Video</p>
          </div>
        </div>
        <div className={video ? "sliderContainer show" : "sliderContainer"}>
          <div className="image-gallery">
            <div className="image-gallery-content">
              <div className="row">
                {initialData.photoCollection.map((item, index) => (
                  <div className="col-md-3" key={index}>
                    <div
                      className="image-container"
                      target={item.id}
                      key={index}
                      onClick={() => handleClickPhoto(item.id)}
                    >
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="video-gallery">
            <div className="row">
              {initialData.videoCollection.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="video-container">
                    <div className="img-container">
                      <img
                        src={item.image}
                        alt=""
                        data-video={item.video}
                        onClick={() => handleClickVideo(item.video)}
                      />
                    </div>
                    <div className="v-overlay">
                      <div className="play-container" data-video={item.video}>
                        <img
                          src={playCircleRegular}
                          alt=""
                          data-video={item.video}
                          onClick={() => handleClickVideo(item.video)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className={
            showPhotoModal
              ? "image-gallery-detail show"
              : "image-gallery-detail"
          }
        >
          <div
            className={showPhotoModal ? "overlay2 show" : "overlay2"}
            onClick={() => setshowPhotoModal(false)}
          ></div>

          <div className="image-container">
            {showPhotoModal &&
              initialData.photoCollection.map((item) => (
                <div className="dmmy">
                  {item.id === imageId && <img src={item.image} alt="" />}
                </div>
              ))}

            <div
              className="icon-close"
              onClick={() => setshowPhotoModal(false)}
            >
              <img src={closeCircle} alt="" />
            </div>
            {imageId < 8 && (
              <div className="next-button arrow">
                <img
                  src={next}
                  alt=""
                  onClick={() => setimageId(imageId + 1)}
                />
              </div>
            )}
            {imageId > 1 && (
              <div className="prev-button arrow">
                <img
                  src={prev}
                  alt=""
                  onClick={() => setimageId(imageId - 1)}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className={
            showVideoModal
              ? "video-gallery-detail show"
              : "video-gallery-detail "
          }
        >
          <div
            className={showVideoModal ? "overlay3 show" : "overlay3 "}
            onClick={handleVideoClose}
          ></div>
          <div className="video-container">
            <div className="icon-close" onClick={handleVideoClose}>
              &times;
            </div>
            <iframe src={showVideoModal && videoLink} title="video"></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}
