import React from "react";
import Slider from "react-slick";
/* import mediaSlider1 from "../../images/media-slider-1.png";
import mediaSlider2 from "../../images/media-slider-2.png";
import mediaSlider3 from "../../images/media-slider-3.png";
import newRoadGudpak from "../../images/new-road-gudpak.jpg";
import makkuseNewsSlider from "../../images/makkuse-news-slider.jpg"; */
import SlickArrow from "../../Components/SlickArrow";
export default function MakkuseMedia() {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    autoplaySpeed: 1000,
    speed: 2000,
    dots: true,
    arrows: true,
    prevArrow: <SlickArrow type="prev" />,
    nextArrow: <SlickArrow type="next" />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="section-makkuse-media">
      <div className="header underline">
        <h2>Makkusé in Media</h2>
      </div>

      <Slider {...settings}>
        <div className="card">
          <a
            href="https://english.onlinekhabar.com/makkuse-miss-nepal-startup-can-make-nepal-identity-more-delicious.html"
            target="_blank"
            rel="noreferrer"
          >
            <div className="image-container">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/media-slider-1.png`}
                alt=""
              />
            </div>
            <div className="text-container">
              <div className="card--title">
                <p className="sub-title">
                  Makkusé: The Miss Nepal’s startup can make Nepal’s identity
                  more delicious
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="card">
          <a
            href="https://myrepublica.nagariknetwork.com/mycity/news/makkuse-a-local-luxury-brand-with-authentic-nepali-taste"
            target="_blank"
            rel="noreferrer"
          >
            <div className="image-container">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/media-slider-2.png`}
                alt=""
              />
            </div>
            <div className="text-container">
              <div className="card--title">
                <p className="sub-title">
                  Makkusé: A local luxury brand with authentic Nepali taste
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="card">
          <a
            href="https://www.nepalitrends.com/former-miss-nepal-anushka-starts-local-brand-makkuse/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="image-container">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/media-slider-3.png`}
                alt=""
              />
            </div>
            <div className="text-container">
              <div className="card--title">
                <p className="sub-title">
                  Former Miss Nepal Anushka starts local brand Makkusé.
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="card">
          <a
            href="https://english.onlinekhabar.com/gudpak-nepali-food-with-visible-cultural-connection-impressive-popularity-and-semi-known-history.html"
            target="_blank"
            rel="noreferrer"
          >
            <div className="image-container">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/new-road-gudpak.jpg`}
                alt="New Road Gundpak"
              />
            </div>
            <div className="text-container">
              <div className="card--title">
                <p className="sub-title">
                  Gudpak: Nepali food with visible cultural connection,
                  impressive popularity and semi-known history
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="card">
          <a
            href="https://thekathmandumail.com/news/398"
            target="_blank"
            rel="noreferrer"
          >
            <div className="image-container">
              <img
                src={`${process.env.REACT_APP_BASE_URL}/images/makkuse-news-slider.jpg`}
                alt="Makkuse pustakari"
              />
            </div>
            <div className="text-container">
              <div className="card--title">
                <p className="sub-title">
                  Makkusé : A new era of Gundpak and Pustakari
                </p>
              </div>
            </div>
          </a>
        </div>
      </Slider>
    </section>
  );
}
