import React from "react";
import { Link, useLocation } from "react-router-dom";

const SideBar = () => {
  const location = useLocation();
  return (
    <div className="sidebar">
      <div className="text-container">
        <div className="header-box">
          <h5>Category</h5>
        </div>
        <ul>
          <li>
            <Link
              to="/product-pustakari"
              className={`${
                location.pathname === "/product-pustakari" && "active"
              }`}
            >
              Pustakari
            </Link>
          </li>
          <li>
            <Link
              to="/product-cookies"
              className={`${
                location.pathname === "/product-cookies" && "active"
              }`}
            >
              Cookies
            </Link>
          </li>
          <li>
            <Link
              to="/product-gundpak"
              className={`${
                location.pathname === "/product-gundpak" && "active"
              }`}
            >
              Gundpak
            </Link>
          </li>
        </ul>
        <div className="header-box">
          <h5>Combo Boxes</h5>
        </div>
        <ul>
          <li>
            <Link
              to="/product-combo-pack"
              className={`${
                location.pathname === "/product-combo-pack" && "active"
              }`}
            >
              Special Packs
            </Link>
          </li>
          <li>
            <Link
              to="/product-luxury-box"
              className={`${
                location.pathname === "/product-luxury-box" && "active"
              }`}
            >
              The Ultimate Luxury Box
            </Link>
          </li>
          <li>
            <Link
              to="/product-small-gift-box"
              className={`${
                location.pathname === "/product-small-gift-box" && "active"
              }`}
            >
              Small Gift Box
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
