import React from "react";
import SecondaryBanner from "../Components/SecondaryBanner";
import { useLocation } from "react-router-dom";
import SEO from "../Components/Seo";
const DesertBar = () => {
  const propData = {
    bannerTitle: "Makkusé Desserts Bar",
    bannerDetail: "",
    bannerImg: `/new-img/wrap.JPG `,
  };
  const location = useLocation();
  return (
    <div>
      {location.pathname === "/makkuse-dessert-bar" && (
        <SEO
          title="Makkusé Desserts Bar  | Official outlet of Makkusé"
          description="Makkusé Desserts Bar is the official outlet of Makkusé where we welcome you to explore and purchase our exceptional products directly."
          keywords="Cheesecake,Kiwi Gundpak Tart,Jeri Swari,Swiss Roll,Coffee, Dark Chocolate Slice,Dessert bar,popular desserts,Dessert in nepal"
          canonical="https://makkuse.com/makkuse-dessert-bar"
        />
      )}
      <SecondaryBanner bannerInfo={propData} />
      <div className="container">
        <div className="d-img-wrapper">
          <img src="/new-img/makuse.jpg" alt="" />
          <img src="/new-img/table.jpg" alt="" />
        </div>
        <p>
          Located in the vibrant heart of Thamel, Makkusé Desserts Bar is the
          official outlet of Makkusé where we welcome you to explore and
          purchase our exceptional products directly. With the copper themed
          interior, each corner whispers tales of rich Nepali heritage,
          promising an ambience that fascinates not just your taste buds but
          also the soul. Whether you're seeking a quiet spot to work on your
          laptop or a cozy space to catch up with your pal, Makkusé Desserts Bar
          offers the quintessential setting.{" "}
        </p>
        <br />
        <p className="pb-2">
          We take pride in our unique desserts menu available exclusively at
          Makkusé Desserts Bar that showcases a tapestry of experimental and
          fusion recipes, especially featuring our flagship delights, Makkusé
          Gundpak and Pustakari, as the key ingredients:
        </p>

        <div className=" mt-4">
          <div className="content-wrapper">
            <div className="text-wrapper">
              <h2>Gundpak Cheesecake</h2>
              <p className="mt-3">
                Made with super creamy cheese and luscious Gundpak-infused
                crust, the winning recipe of our Gundpak Cheesecake strikes a
                perfect balance of tart and sweet, satisfying all your cravings!
              </p>
            </div>
            <div className="image-wrapper">
              <img src="/new-img/cheesecake.jpg" alt="Gundpak Cheesecake" />
            </div>
          </div>
          <div className="content-wrapper">
            <div className="image-wrapper">
              <img src="/new-img/kiwi.jpg"></img>
            </div>
            <div className="text-wrapper">
              <h2>Kiwi Gundpak Tart</h2>
              <p className="mt-3">
                Pretty, Yummy and Healthy- Our Kiwi Gundpak Tart is an
                allrounder dessert. The creamy fillings of Gundpak, fresh
                toppings of kiwis and buttery-crunchy tart shells is THE treat
                you can binge with a dash of fresh green Vitamin C!
              </p>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="text-wrapper">
              <h2>Jeri Swari</h2>
              <p className="mt-3">
                Get your sweet tooth fixed in a nostalgic way- Our sweet Jeri
                Swari will take you back to the memory lane- revisiting the fond
                memories of early breakfasts, enjoying the beloved Jeri Swari
                from the nearby alleys.
              </p>
            </div>
            <div className="image-wrapper">
              <img src="/new-img/jeri.jpg"></img>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="image-wrapper">
              <img src="/new-img/Gundpak.jpg" />
            </div>
            <div className="text-wrapper">
              <h2>Gundpak Swiss Roll</h2>
              <p className="mt-3">
                Pretty, Yummy and Healthy- Our Kiwi Gundpak Tart is an
                allrounder dessert. The creamy fillings of Gundpak, fresh
                toppings of kiwis and buttery-crunchy tart shells is THE treat
                you can binge with a dash of fresh green Vitamin C!
              </p>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="text-wrapper">
              <h2>Pour Over Coffee</h2>
              <p className="mt-3">
                Sip in or take away – our pour-over coffee packs the perfect
                punch of energy. Crafted by hand, each cup guarantees complete
                saturation of the coffee grounds, ensuring a balanced and rich
                brew.
              </p>
            </div>
            <div className="image-wrapper">
              <img src="/new-img/pour.jpg" />
            </div>
          </div>
          <div className="content-wrapper">
            <div className="image-wrapper">
              <img src="/new-img/chocolate.jpg"></img>
            </div>
            <div className="text-wrapper">
              <h2>Gundpak Dark Chocolate Slice</h2>
              <p className="mt-3">
                Experience pure bliss with Gundpak Dark Chocolate Slice - a
                harmonious blend of bittersweet dark chocolate ganache and
                creamy layers atop a sweet Gundpak crumb base!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="map mt-4">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.0636000712047!2d85.30767517524035!3d27.715322525140014!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb190b547a1c09%3A0x10fec5d48318eb98!2sMakkus%C3%A9%20Desserts%20Bar!5e0!3m2!1sen!2snp!4v1699178851229!5m2!1sen!2snp"
          width="100%"
          style={{
            border: "0",
            aspectRatio: "4/3",
            Width: "100%",
            height: "500px",
          }}
          allowfullscreen=""
          loading="lazy"
          title="makkuse"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default DesertBar;
