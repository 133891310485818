import React, { useEffect } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import ShopModal from "../../Components/ShopModal";

const LhosarBlog = () => {
  useEffect(() => {
    document.title = "Celebrating Lhosar with Makkusé | Makkusé";
  }, []);

  const initialData = {
    secondayBanner: {
      bannerTitle: "Celebrating Lhosar with Makkusé",
      bannerDetail: "",
      bannerImg: `${process.env.REACT_APP_BASE_URL}/images/blogs/lhosar-blog.jpg`,
      page: "",
    },
  };
  return (
    <main className="blogs">
      <SecondaryBanner bannerInfo={initialData.secondayBanner} />
      <section className="section-blog" style={{ marginBlock: "100px" }}>
        <div className="container">
          {/* <div className="sub-topic">
            <h2 style={{ fontWeight: "400" }}>
              There are so many exciting conversations waiting to happen within
              the Nepali dessert space – here are some thoughts from our team,
              clients and enthusiasts! Do have a read and leave your thoughts!
            </h2>
          </div> */}
          <div className="text-container">
            <div className="para">
              <p>
                Nepal, a country home to diverse ethnicities, rich culture and
                vibrant festivals. One among many celebrations, Lhosar (Tibetan:
                ལོ་གསར་, Wylie: lo-gsar; Lo/Lho, meaning Year, and Sar/Chhar,
                New) is one of the biggest festivals celebrated by the Sherpa,
                Tamang, Tibetan, Hyolmo and Gurung communities in Nepal and some
                parts of India. Historically a harvest festival that is now a
                grandeur of celebration marking the New Year. It is celebrated
                on Shukla Pratipada; the first day after the new moon, following
                the cycle of the moon (Lunar Calendar or Lunisolar Calendar
                Year).
              </p>
              <p>
                Different communities have their own interpretations of Lhosar
                and are celebrated on diverse days, but nevertheless all of them
                celebrate this festival with great significance and
                extravagance. Tol Losar, celebrated on Paush Shukla Pratipada is
                the oldest/ancient Lhosar event, also called Samay Chakra
                Samvatsara (Cycle of time). On Paush Shukla Ashtami
                (December/January), the Gurung people celebrate Tamu Lhosar. The
                following month on Magh Shukla Pratipada, after the winter
                solstice (January/February), people of Tamang and Hyolmo (Yolmo)
                Community celebrate Sonam Lhosar/Lhochhar. Gyalpo Lhosar, also
                known as Tibetan New Year is celebrated from Falgun Shukla
                Pratipada by the Sherpa people over the course of 15 days.
              </p>
              <p>
                Since ancient times there is a tradition of associating years
                with twelve different animal species in all the communities that
                celebrate Lhosar. These twelve animals are - Rat, Ox, Tiger,
                Rabbit, Dragon, Snake, Horse, Goat/Sheep, Monkey, Rooster, Dog,
                Pig/Boar. One animal species, referred to as ‘Barga’ is assigned
                to each year in a repeating twelve-year cycle. When there was no
                calendar system, the cycle was used to calculate years and
                people’s age. The Chinese New Year/Lunar New Year also follows
                the very same tradition. According to the Chinese Lunar
                Calendar, 2023 is the year of the Water Rabbit, the Rabbit is
                the symbol of peace, prosperity and longevity.
              </p>
              <p>
                The Lhosar celebrations revolve around food, family and
                festivities. People wear traditional attires to participate in
                cultural events, rallies. Ceremonial rituals, traditional songs
                and dances are performed with utmost involvement. It is an
                auspicious occasion to commemorate the deities, ancestors and
                perform rituals of gratitude and offerings. One of the most
                beautiful aspects of Lhosar is witnessing people cherish their
                roots, roots of one’s culture, family and community. It is a
                heartwarming sight to see families get together, reunite and
                rejoice for the Lhosar Celebration, sharing good wishes,
                blessings and devouring delicious food. Food is a very
                significant part of the Lhosar Celebrations, special delicacies
                are prepared including Khapse (a crispy deep-fried pastry,
                signature lhosar snack of the Tamang and Sherpa), Babar (Roti
                made of rice), Guthuk (a soupy dish made of beans, vegetables,
                meat, etc. in Sherpa or Tibetan Cuisine).
              </p>
              <p>
                Lhosar is truly an all there celebration of culture and
                tradition, from arts to people to cuisines, everything is
                honored with impressive indulgence. Adding on to the traditions
                of embracing bonds, sharing blessings and gestures of gratitude,
                this Lhosar share the culturally rich, authentic nepali taste;
                Makkusé Lhosar Box, with your family and loved ones and elevate
                the celebratory sweetness.
              </p>
              <p>
                May this new year bring you the best of prosperity, joy and
                exuberance. May there always be love, light and laughter. Happy
                Lhosar!
              </p>
            </div>
            <div className="bottom-text">
              <p>Written By:</p>
              <p>Puja Tamang</p>
            </div>
          </div>
        </div>
      </section>
      <ShopModal />
    </main>
  );
};

export default LhosarBlog;
