import React, { useEffect } from "react";
import SecondaryBanner from "../../Components/SecondaryBanner";
import ShopModal from "../../Components/ShopModal";
// import ourstoryBanner from "../../images/makkuse-lux.jpeg";
import GalleryContainer from "./GalleryContainer";
export default function Gallery() {
  useEffect(() => {
    document.title = "Gallery | Makkusé";
  }, []);
  const initialData = {
    secondayBanner: {
      bannerTitle: "Gallery",
      bannerDetail: "",
      bannerImg: `${process.env.REACT_APP_BASE_URL}/images/makkuse-lux.jpeg`,
    },
  };
  return (
    <main className="page-testimonials">
      <SecondaryBanner bannerInfo={initialData.secondayBanner} />
      <GalleryContainer />
      <ShopModal />
    </main>
  );
}
