import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { lazy, Suspense } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
//importing page
/* const Home = lazy(() => import("./Pages/Home/Home"));
const OurStory = lazy(() => import("./Pages/OurStory/OurStory"));
const Gallery = lazy(() => import("./Pages/Gallery/Gallery"));
const Product = lazy(() => import("./Pages/Product/Product"));
const ProductDetail = lazy(() => import("./Pages/Product/ProductDetail"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const CustomizedGifting = lazy(() =>
  import("./Pages/CustomizedGifting/CustomizedGifting")
);
const TestimonialPage = lazy(() =>
  import("./Pages/Testimonial/TestimonialPage")
); */
import Home from "./Pages/Home/Home";
import OurStory from "./Pages/OurStory/OurStory";
import Gallery from "./Pages/Gallery/Gallery";
import Product from "./Pages/Product/Product";
import ProductDetail from "./Pages/Product/ProductDetail";
import Contact from "./Pages/Contact/Contact";
import CustomizedGifting from "./Pages/CustomizedGifting/CustomizedGifting";
import TestimonialPage from "./Pages/Testimonial/TestimonialPage";
import MakkuseBlogs from "./Pages/Blogs/MakkuseBlogs";
import NepaliSagun from "./Pages/Blogs/NepaliSagun";
import PustakariBlog from "./Pages/Blogs/PustakariBlog";
import LhosarBlog from "./Pages/Blogs/LhosarBlog";
import { HelmetProvider } from 'react-helmet-async';
import DesertBar from "./Pages/desertBar";
const helmetContext = {};
function App() {

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/our-story" element={<OurStory />}></Route>
            <Route exact path="/:id" element={<Product />} />
            <Route exact path="products/:id" element={<ProductDetail />} />
            <Route path="/gallery" element={<Gallery />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/makkuse-dessert-bar" element={<DesertBar />}></Route>
            <Route
              path="/customizedGifting/:id"
              element={<CustomizedGifting />}
            ></Route>
            <Route path="/testimonial" element={<TestimonialPage />} />
            <Route path="/blog" element={<MakkuseBlogs />} />
            <Route path="/blog/nepali-sagun" element={<NepaliSagun />} />
            <Route
              path="/blog/pustakari-confection"
              element={<PustakariBlog />}
            />
            <Route path="/blog/lhosar" element={<LhosarBlog />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
